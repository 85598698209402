import React, { PureComponent } from "react"
import styled from "styled-components"
import ListingList from "dumbComponents/Listings/components/ListingList"
import colors from "@colors"
import cookie from "services/CookieStorage"
import { DownOutlined } from "@ant-design/icons"
import TCOfferBanner from "dumbComponents/TransactionCoordinationV2/Navbar/TCOfferBanner"
import { ATC } from "@fonts"
import TechAssetsContainer from "container/TechAssets"
import TeamProfileContainer from "container/Realty/TeamProfileContainer"
import TCContainer from "container/Realty/TransactionFormContainer"
import TeamFilterComponent from "dumbComponents/Listings/components/TeamFilterComponent"
import { withRouter } from "react-router-dom"
import icons from "@icons"
import ZeroState from "dumbComponents/common/ZeroState"
import Loader from "@ui/Loader"
import PlusIcon from "images/icons/WhiteIconPlus.png"
import ConfirmToGoToContracts from "dumbComponents/Listings/components/ConfimToGoToContract"
import ConfirmToExecute from "dumbComponents/Listings/components/ConfirmToExecute"
import ViewListingHistory from "dumbComponents/Listings/components/ViewListingHistory"
import UploadListingImages from "dumbComponents/Listings/components/UploadListingImages"
import PreviewImages from "dumbComponents/Listings/components/PreviewImages"
import AddTransactionPopup from "dumbComponents/Listings/components/AddTransactionPopup"
import SearchIcon from "dumbComponents/RealtyV3/RARRecruitment/images/Search.svg"
import {
  MONTH_OPTIONS,
  YEAR_OPTIONS,
} from "shared/consts"
import {
  getFirstAndLastDateFromMonth,
  getFirstAndLastDateFromYear,
} from "services/Utils"
import {
  Button, Select, Input, Dropdown, Space, Empty,
} from "@ui/antd"
const { Search } = Input
// import "antd/dist/antd.css"

const Wrap = styled.div`
  margin-top: 62px;
  .ant-select-selector {
    width: 200px;
    border-radius: 4px;
  }
`

const ListingContainer = styled.div`
  margin-top: 28px;
`

const AddNewListing = styled.button`
  height: 36px;
  width: 188px;
  border-radius: 100px;
  background: ${colors.new_primary};
  color: ${colors.white};
  cursor: pointer;
  border: none;
  padding-left: 12px;
  padding-right: 12px;

  span img {
    height: 15px;
    position: relative;
    left: 11px;
    top: 3px;
  }
`
const ListingHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  ${props => props.mt && `
    margin-top: ${props.mt}
  `}
  ${props => props.fd && `
    flex-direction: ${props.fd}
  `}
`
const DropDownHeader = styled.div`
  display: flex;
  align-items: baseline;
  font-size: 22px;
  margin-left: 5px;
  h2 {
    
  }
  span {
    margin-left: 5px;
  }
`

const FilterSpace = styled.div`
  display: flex;
  .ant-select-selector {
    border: 1px solid rgb(199, 199, 199) !important;
  }
`
const OfferHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => props.jc || "space-between"};
  ${props => props.mt && `
    margin-top: ${props.mt}
  `}
  ${props => props.fd && `
    flex-direction: ${props.fd}
  `}
`

const FilterInnerSpace = styled.div`
  display: flex;
`

class Listings extends PureComponent {
  state = {
    selectedMenuValue: "",
    team_member_id: null,
    searchText: "",
    statusFilter: "",
  }

  componentDidMount() {
    const {
      getAllListings, getListingStatusMap, getGoogleAuthToken, getTCBanner,
    } = this.props
    const user = cookie.load("user") || {}
    const agentId = user.id
    getGoogleAuthToken()
    getAllListings({
      skip: 0,
      limit: 200,
      agentId: user.agent_id || user.id,
      forListing: true,
      include: "in-contract-listings",
      transactionType: "Listing",
    })
    getListingStatusMap()
  }

  setStatusFilter = (value) => {
    this.setState({
      statusFilter: value,
    })
  }

  getFiltersOptions = () => {
    const { getTeamDetailsResponse } = this.props
    const {
      data: teamDetails,
    } = getTeamDetailsResponse || {}
    const user = cookie.load("user") || {}
    const teamNames = (teamDetails && teamDetails.team_members) ? teamDetails.team_members.filter(td => td.agent_id !== user.id).map(tm => ({
      key: `${tm.agent_id}::${tm.firstname}'s Listings`,
      label: `${tm.firstname}`,
    })) : []

    return [
      ...teamNames,
    ]
    // { value: "view_specific_team_member", label: `${td.firstname || ""}
  }

  handleMenuClick = (e, multiselect) => {
    let event = e
    let allTeamMemberIds = []
    event = e[0]
    const menberKey = e.key.split("::")
    const memberId = parseInt(menberKey[0], 10)
    allTeamMemberIds.push(memberId)
    const keys = e.key.split("::")
    const team_member_id = (e) ? allTeamMemberIds : null
    this.setState({
      selectedMenu: keys ? keys[1] : "My Listings",
      selectedMenuValue: keys ? keys[0] : "",
      team_member_id,
    })
    this.handleFilterSelect(team_member_id, keys ? keys[0] : "")
  }

  handleFilterSelect = (teamMemberId, selectedMenuVal) => {
    const { getAllListings } = this.props
    const { team_member_id, selectedMenuValue } = this.state
    const payload = {}
    if (teamMemberId && selectedMenuVal) {
      payload.memberAgentId = teamMemberId || team_member_id
    }
    if (selectedMenuVal && teamMemberId) {
      payload.filterBy = "member"
    }
    if (selectedMenuVal === "all") {
      payload.filterBy = "all"
    }
    console.log("Eval===", payload)
    const user = cookie.load("user") || {}
    getAllListings({
      skip: 0,
      limit: 200,
      agentId: user.id,
      forListing: true,
      include: "in-contract-listings",
      ...payload,
      apiMethod: "post",
      transactionType: "Listing",
    })
  }

  searchOnLocal = (text) => {
    this.setState({
      searchText: text,
    })
  }

  getFilteredListings = () => {
    const { getAllListingsResponse } = this.props
    const { searchText: text, statusFilter } = this.state
    const searchText = text ? text.toLowerCase() : ""
    // displayDealStatus
    if (getAllListingsResponse.data) {
      const filterBySearchdeals = getAllListingsResponse.data?.filter(dt => dt?.listing_address?.toLowerCase()?.includes(searchText) || dt.city?.toLowerCase()?.includes(searchText) || dt.state?.toLowerCase()?.includes(searchText) || dt.client_name?.toLowerCase()?.includes(searchText) || !searchText)
      const filterByStatusDeals = filterBySearchdeals.filter(dt => dt.displayDealStatus === statusFilter || !statusFilter)
      return filterByStatusDeals
    }
    return []
  }

  addNewListing = () => {
    const tempToken = cookie.load("google_access_token")
    const {
      setDealToView,
      history,
      setLoaderOnAgentInfo,
      googleAuthToken,
      resetContractsForm,
      resetListingsForm,
      setEditingDealTeamMemberId,
      resetForm,
      toggleAddTransactionPopup,
      setCurrentContractID,
      setOfferIdToView,
    } = this.props
    setCurrentContractID(null)
    setLoaderOnAgentInfo(true)
    setOfferIdToView(null)
    setDealToView(null)
    resetContractsForm()
    resetListingsForm()
    // resetLastDealAction
    // getOfferDocumentsResponse
    // setEditingDealTeamMemberId(null)
    resetForm()
    // history.push("/realty/listings/coversheet/integrations")
    // setEditingDealTeamMemberId(null)
    if (googleAuthToken?.data?.access_token) {
      history.push("/realty/listings/coversheet/listing-agent-info")
    } else {
      history.push("/realty/listings/coversheet/integrations")
    }
  }

  addNewListingIntiate = () => {
    const { toggleAddTransactionPopup, getTeamDetailsResponse, setEditingDealTeamMemberId } = this.props
    const {
      data: teamDetails,
    } = getTeamDetailsResponse || {}
    if (teamDetails?.id) {
      toggleAddTransactionPopup(true)
    } else {
      setEditingDealTeamMemberId(null)
      this.addNewListing()
    }
  }

  render() {
    const {
      history, getAllListingsResponse, getTeamDetailsResponse, showViewListingHistory,
      getListingStepsResponse, showUploadListingImages, previewListingImages, toggleAddTransactionPopup,
    } = this.props
    const {
      data: teamDetails,
      isFetching: fetchingTeamDetails,
    } = getTeamDetailsResponse || {}
    const user = cookie.load("user") || {}
    const agentId = user.id
    return (
      <>
        {/* <TCOfferBanner onClick={null} agentId={agentId} /> */}
        <Wrap>
          <ConfirmToGoToContracts
            isListing
          />
          <AddTransactionPopup
            subtextForLead="Add Listing for Yourself or on behalf of your team member. Please select from dropdown"
            subtextForTC="Add Listing on behalf of your team. Please select from dropdown"
            header="Add Listing"
            callback={this.addNewListing}
          />
          <ConfirmToExecute />
          {showUploadListingImages && (<UploadListingImages />)}
          {previewListingImages && (<PreviewImages />)}
          {showViewListingHistory && (
            <ViewListingHistory />
          )}
          <AddNewListing
            className="add_new_listing"
            onClick={() => { this.addNewListingIntiate() }}
          >
            Add New Listing
            <span>
              <img src={PlusIcon} alt="test" />
            </span>
          </AddNewListing>
          <ListingHeader>
            {teamDetails && teamDetails.id ? (
              <OfferHeader
                jc="space-between"
              >
                <TeamFilterComponent
                  handleMenuClick={this.handleMenuClick}
                  firstValueOption={{ key: "::My Listings", label: "My Listings" }}
                  secondValueOption={{ key: "all::Team Listings", label: "Team Listings" }}
                  firstValueLabel="My Listings"
                  secondValueLabel="My Team"
                  defaultInitialName="My Listings"
                />
              </OfferHeader>
            ) : (
              <p
                style={{
                  fontFamily: `${ATC}`,
                  fontSize: "24px",
                  fontWeight: "400",
                }}
              >
                My Listings
              </p>
            )}
            <FilterInnerSpace>
              <FilterSpace>
                <Search
                  placeholder="Search by address or agent name"
                  onSearch={(value) => { this.searchOnLocal(value) }}
                  style={{ width: 300, border: "1px solid #C7C7C7", borderRadius: "4px" }}
                />
              </FilterSpace>
              {/* </ListingHeader>
            <ListingHeader> */}
              {/* {teamDetails && teamDetails.id && (
                // <Dropdown
                //   overlayStyle={{
                //     fontSize: 40,
                //   }}
                //   menu={{
                //     items: this.getFiltersOptions(),
                //     onClick: this.handleMenuClick,
                //   }}
                // >
                //   <DropDownHeader>
                    // <span><DownOutlined /></span>
                //   </DropDownHeader>
                // </Dropdown>
              )} */}
              <FilterSpace>
                <Select
                  style={{
                    width: 200,
                    marginLeft: "10px",
                    border: "1px solid black !important",
                    fontFamily: `${ATC}`,
                    fontWeight: "100",
                  }}
                  placeholder="Select Status"
                  onChange={(value) => { this.setStatusFilter(value) }}
                  allowClear
                  options={[
                    {
                      value: "On Market",
                      label: "On Market",
                    },
                    {
                      value: "Incomplete",
                      label: "Incomplete",
                    },
                    {
                      value: "Coming Soon",
                      label: "Coming Soon",
                    },
                    {
                      value: "Pending",
                      label: "Pending",
                    },
                    {
                      value: "Expired",
                      label: "Expired",
                    },
                    {
                      value: "Withdrawn",
                      label: "Withdrawn",
                    },
                  ]}
                />
              </FilterSpace>
            </FilterInnerSpace>
          </ListingHeader>
          <ListingContainer>
            {getAllListingsResponse.isFetching || getListingStepsResponse.isFetching ? (
              <Loader />
            ) : (
              <>
                {this.getFilteredListings().length ? (
                  <>
                    {this.getFilteredListings().map(dt => (<ListingList listingData={dt} />))}
                  </>
                ) : (
                  <>
                    <ZeroState
                      height="474px"
                      width="100%"
                      backgroundColor="#F9F9F7"
                      text="No Listing Found"
                      logo=""
                      onClickHandler={() => { }}
                      displayStyle="flex"
                      alignItems="center"
                      justifyContent="center"
                      buttonCTAText=""
                      innerBoxDisplayStyle="flex"
                      innerBoxFlexDirection="column"
                      innerBoxContentJustification="center"
                      innerBoxItemAlign="center"
                    />
                  </>
                )}
              </>
            )}
          </ListingContainer>
        </Wrap>
      </>
    )
  }
}

export default TCContainer(withRouter(TechAssetsContainer(TeamProfileContainer(Listings))))
