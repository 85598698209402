import React, { useEffect, useState } from "react"
import Listings from "container/Listings"
import OffersV2 from "container/OffersV2"
import TechAssetsContainer from "container/TechAssets"
import { Table } from "antd"
import cookie from "services/CookieStorage"
import { withRouter } from "react-router-dom"
import Loader from "@ui/Loader"
import { COVERSHEET } from "dumbComponents/OffersV3/consts"
import {
  InputPopover,
} from "dumbComponents/common/InputFields"
import TeamProfileContainer from "container/Realty/TeamProfileContainer"
import AddressInformationCard from "./AddressInformationCard"
import AgentName from "./AgentName"
import {
  TableWrap,
} from "./styles"
import SelectStatus from "../../../SelectStatus/index"
import ContractStatus from "./ContractStatus"
import DeleteTransaction from "./DeleteTransaction"
import { DeleteModal, TransactionModal } from "../Modals"
import {
  OFFER, CONTRACT, LISTING,
  PAGINATION_LIMIT,
} from "../../../../const"
const TransactionTable = ({
  fetchTransactionDataResponse,
  transactionDataObject,
  getOfferStatusMappingsResponse,
  fetchContractStatusesResponse,
  deleteOffer,
  deleteOfferResponse,
  deleteListing,
  deleteListingResponse,
  fetchTransactionData,
  currentTransactionDashboardTab,
  constructTransaction,
  transactionQuery,
  performNecessaryActionsBeforeLoadingListing,
  viewContractForListing,
  updateCurrentListingData,
  listingData,
  toggleMoveToMarket,
  toggleStatusChangeModal,
  toggleActiveTab,
  toggleOfferToContractConversion,
  toggleStatusChangeModalOffer,
  setOfferIdToView,
  clearExistingData,
  clearOffersRelatedData,
  setEditingDealTeamMemberId,
  history,
  setDealToView,
  setListingAddress,
  setStateForOffer,
  performNecessaryActionsBeforeLoadingListingResponse,
  setListingId,
  setCurrentListingsState,
  deleteContract,
  deleteContractResponse,
}) => {
  const [openDeleteTransactionModal, setDeleteTransactionModal] = useState(false)
  const [deleteTransactionId, setDeleteTransactionId] = useState(null)
  const [recordType, setRecordType] = useState(null)
  const {
    isFetching: gettingTransactionData,
    data: transactionData,
  } = fetchTransactionDataResponse || {}

  const {
    isFetching: processing,
  } = performNecessaryActionsBeforeLoadingListingResponse || {}

  const {
    isFetching: deletingOffer,
  } = deleteOfferResponse || {}

  const {
    isFetching: deletingListing,
  } = deleteListingResponse || {}

  const {
    isFetching: deletingContract,
  } = deleteContractResponse || {}

  const {
    isFetching: gettingOfferStatuses,
    data: offerStatus,
  } = getOfferStatusMappingsResponse || {}

  const {
    isFetching: gettingStatuses,
    data: transactionStatuses,
  } = fetchContractStatusesResponse || {}

  const {
    listingStatus,
    contractStatus,
  } = transactionStatuses || {}

  const getTotalCount = () => {
    if (transactionData) {
      return transactionData.totalCountOfTransactions || 0
    }
    return 0
  }

  const handlePaginationChange = (page) => {
    const query = {
      ...transactionQuery,
    }

    if (page === 1) {
      query.skip = 0
      query.limit = PAGINATION_LIMIT
      constructTransaction({
        query,
      })
    } else {
      const calculatedSkip = Math.abs((page * PAGINATION_LIMIT) - PAGINATION_LIMIT)
      query.skip = calculatedSkip
      query.limit = PAGINATION_LIMIT
      constructTransaction({
        query,
      })
    }
  }
  const getContractStatus = (value) => {
    if (contractStatus) {
      const status = contractStatus.find(item => item.value === value)
      const labelStatus = status ? status.display_name : "Incomplete"
      return labelStatus
    }
    return "Incomplete"
  }

  const deleteTransaction = () => {
    if (recordType == OFFER) {
      deleteOffer({
        offerId: deleteTransactionId,
        callback: () => {
          setRecordType(null)
          setDeleteTransactionId(null)
          setDeleteTransactionModal(false)
          const curr = {
            ...transactionQuery,
          }
          curr.clientTransactionType = currentTransactionDashboardTab

          constructTransaction({
            query: curr,
          })
        },
      })
    } else if (recordType == LISTING) {
      deleteListing({
        id: deleteTransactionId,
        callback: () => {
          setRecordType(null)
          setDeleteTransactionId(null)
          setDeleteTransactionModal(false)
          const curr = {
            ...transactionQuery,
          }
          curr.clientTransactionType = currentTransactionDashboardTab

          constructTransaction({
            query: curr,
          })
        },
      })
    } else if (recordType == CONTRACT) {
      deleteContract({
        id: deleteTransactionId,
        callback: () => {
          setRecordType(null)
          setDeleteTransactionId(null)
          setDeleteTransactionModal(false)
          const curr = {
            ...transactionQuery,
          }
          curr.clientTransactionType = currentTransactionDashboardTab

          constructTransaction({
            query: curr,
          })
        },
      })
    }
  }

  const handleRowOfferStatusChange = (value, record) => {
    let finalOfferId = null
    let transactionId = null
    const primaryAgentId = record.agent_id
    if (record && record.clients && record.clients.length > 0) {
      const primaryClient = record.clients.find(client => client.is_primary === 1)

      if (primaryClient && primaryClient.transaction_id) {
        transactionId = primaryClient.transaction_id // Set the transactionId if it exists
      }
    }
    if (record.record_type == CONTRACT) {
      finalOfferId = record.offer_id
      transactionId = record.transaction_id
    } else if (record.record_type == OFFER) {
      finalOfferId = record.id
      transactionId = record.transaction_id
    }

    if (value === "offer_accepted") {
      setEditingDealTeamMemberId(parseInt(primaryAgentId, 10))
      toggleActiveTab("contract_details")
      toggleOfferToContractConversion({
        bool: true,
        url: `/realty/offers-v3/${finalOfferId}?agent_id=${primaryAgentId}&transaction_id=${transactionId}&contract_phase=true`,
      })
    } else {
      toggleStatusChangeModalOffer({
        bool: true,
        id: finalOfferId,
        status: value,
        transactionId,
      })
    }
  }

  const handleRowListingStatusChange = (value, record) => {
    let finalListingId = null
    if (record.record_type == "contract") {
      finalListingId = record.listing_id
    } else if (record.record_type == "listing") {
      finalListingId = record.id
    }
    switch (value) {
      case "pending":
        performNecessaryActionsBeforeLoadingListing({
          dealId: finalListingId,
          record,
          suppressNotifications: true,
        })
        viewContractForListing(true)
        break
      case "active_listing":
      case "back_on_market":
        //market modal with mls
        updateCurrentListingData({
          ...listingData,
          dealId: finalListingId,
        })
        if (!record.mls_number || record.mls_number === null || record.mls_number === undefined) {
          toggleMoveToMarket(true)
        } else {
          toggleStatusChangeModal({
            bool: true,
            statusChangedTo: value,
            listingID: finalListingId,
          })
        }
        break
      case "coming_soon":
      case "listing_canceled":
      case "cancelled":
      case "withdrawn":
      case "expired":
        toggleStatusChangeModal({
          bool: true,
          statusChangedTo: value,
          listingID: finalListingId,
        })
        break
      default:
    }
  }

  const openBuyerTransaction = (record) => {
    const contractState = record.state
    if (record.record_type == OFFER || (!record.is_auto_generated && record.offer_id)) {
      const {
        agent_id: primaryagentId,
        offer_id: offerId,
        clients,
        current_offer_status,
      } = record || {}
      let transactionId = null
      if (record && clients && clients.length > 0) {
        const primaryClient = record.clients.find(client => client.is_primary === 1)

        if (primaryClient && primaryClient.transaction_id) {
          transactionId = primaryClient.transaction_id
        }
      }
      clearOffersRelatedData()
      setEditingDealTeamMemberId(parseInt(primaryagentId, 10))
      toggleActiveTab(COVERSHEET)
      setDealToView(offerId)
      if (clients && clients.length > 0) {
        const url = `/realty/offers-v3/${offerId}?transaction_id=${transactionId}&agent_id=${primaryagentId}`
        const sendToContractPhase = current_offer_status === "offer_accepted"
        const newOffer = current_offer_status === "new_offer"
        history.push(`${url}${sendToContractPhase ? "&contract_phase=submitted" : ""}`)
      } else {
        history.push(`/realty/offers-v3/${offerId}`)
      }
      //setCurrentStep(listingData.current_offer_step)
    } else if (record.record_type == CONTRACT) {
      const {
        id: dealId,
        agent_id: primaryAgentId,
        offer_id: dummyOfferId,
        deal_status: dealStatus,
        street,
      } = record || {}
      setDealToView(dealId)
      setListingAddress(street)
      setEditingDealTeamMemberId(parseInt(primaryAgentId, 10))
      if (dealStatus !== "incomplete") {
        if (dummyOfferId) {
          setOfferIdToView(dummyOfferId)
          setStateForOffer(contractState.toLowerCase())
          const url = `/realty/transaction-coordination/contract/form/${dealId}?offerId=${dummyOfferId}`
          history.push(url)
        }
      } else {
        history.push("/realty/transaction-coordination/contract/form")
      }
    }
  }

  const openSellerTransaction = (record) => {
    const contractState = record.state
    if (record.record_type == LISTING || (!record.is_auto_generated && record.listing_id)) {
      const {
        listing_id: listingId,
      } = record || {}
      performNecessaryActionsBeforeLoadingListing({
        dealId: listingId,
        record,
        history,
      })
    } else if (record.record_type == CONTRACT) {
      const {
        id: dealId,
        agent_id: primaryAgentId,
        listing_id: dummyListingId,
        deal_status: dealStatus,
        listing_address: listingAdress,
      } = record || {}
      setDealToView(dealId)
      setListingAddress(listingAdress)
      setEditingDealTeamMemberId(parseInt(primaryAgentId, 10))
      if (dealStatus !== "incomplete") {
        if (dummyListingId) {
          setListingId(dummyListingId)
          setCurrentListingsState(contractState.toLowerCase())
          const url = `/realty/transaction-coordination/contract/form/${dealId}?listingId=${dummyListingId}`
          history.push(url)
        }
      } else {
        history.push("/realty/transaction-coordination/contract/form")
      }
    }
  }

  const columnsBuyers = [
    {
      title: "Property address",
      dataIndex: "street",
      key: "street",
      render: (_, record) => (
        <AddressInformationCard
          data={record}
          currentTransactionTab={currentTransactionDashboardTab}
        />
      ),
      onCell: (record, rowIndex) => ({
        onClick: (event) => {
          setOfferIdToView(null)
          clearExistingData()
          localStorage.removeItem("current_active_local_tab")
          openBuyerTransaction(record)
        },
      }),
    },
    {
      title: "Buyer",
      dataIndex: "client_fullname",
      key: "client_fullname",
      onCell: (record, rowIndex) => ({
        onClick: (event) => {
          setOfferIdToView(null)
          clearExistingData()
          localStorage.removeItem("current_active_local_tab")
          openBuyerTransaction(record)
        },
      }),
    },
    {
      title: "Amount",
      dataIndex: "offer_price",
      key: "offer_price",
      onCell: (record, rowIndex) => ({
        onClick: (event) => {
          setOfferIdToView(null)
          clearExistingData()
          localStorage.removeItem("current_active_local_tab")
          openBuyerTransaction(record)
        },
      }),
    },
    {
      title: "Offer status",
      dataIndex: "current_offer_status",
      key: "current_offer_status",
      render: (_, record) => (
        <SelectStatus
          type="transactionStatus"
          data={record}
          optionListStatus={offerStatus}
          loading={gettingOfferStatuses}
          currentStatus={record.current_offer_status}
          defautlStatus={record.current_offer_status}
          disabledStatuses={["incomplete"]}
          handleStatusChange={(value) => {
            handleRowOfferStatusChange(value, record)
          }}
        />
      ),
    },
    {
      title: "Agent",
      dataIndex: "agent_name",
      key: "agent_name",
      render: (_, record) => (
        <AgentName
          data={record}
        />
      ),
      onCell: (record, rowIndex) => ({
        onClick: (event) => {
          setOfferIdToView(null)
          clearExistingData()
          localStorage.removeItem("current_active_local_tab")
          openBuyerTransaction(record)
        },
      }),
    },
    {
      title: "Submitted on",
      dataIndex: "submitted_date",
      key: "submitted_date",
      onCell: (record, rowIndex) => ({
        onClick: (event) => {
          setOfferIdToView(null)
          clearExistingData()
          localStorage.removeItem("current_active_local_tab")
          openBuyerTransaction(record)
        },
      }),
    },
    {
      title: "Contract submitted date",
      dataIndex: "contract_submitted",
      key: "contract_submitted",
      onCell: (record, rowIndex) => ({
        onClick: (event) => {
          setOfferIdToView(null)
          clearExistingData()
          localStorage.removeItem("current_active_local_tab")
          openBuyerTransaction(record)
        },
      }),
    },
    {
      title: "Contract status",
      dataIndex: "deal_status",
      key: "deal_status",
      render: (_, record) => (
        <ContractStatus
          data={record}
          contractStatus={record.deal_status}
          labelStatus={getContractStatus(record.deal_status)}
        />
      ),
      onCell: (record, rowIndex) => ({
        onClick: (event) => {
          setOfferIdToView(null)
          clearExistingData()
          localStorage.removeItem("current_active_local_tab")
          openBuyerTransaction(record)
        },
      }),
    },
    {
      title: "Last updated on",
      dataIndex: "updated_date",
      key: "updated_date",
      onCell: (record, rowIndex) => ({
        onClick: (event) => {
          setOfferIdToView(null)
          clearExistingData()
          localStorage.removeItem("current_active_local_tab")
          openBuyerTransaction(record)
        },
      }),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <DeleteTransaction
          data={record}
          currentContractStatus={record.deal_status}
          handleDelete={() => {
            if (record.record_type === OFFER) {
              setDeleteTransactionId(record.id)
              setRecordType(OFFER)
            } else if (record.record_type === CONTRACT) {
              if (record.offer_id) {
                setDeleteTransactionId(record.offer_id)
                setRecordType(OFFER)
              } else {
                setDeleteTransactionId(record.id)
                setRecordType(CONTRACT)
              }
            }
            setDeleteTransactionModal(true)
          }}
        />
      ),
    },
  ]
  const columnsSeller = [
    {
      title: "Property address",
      dataIndex: "listing_address",
      key: "listing_address",
      render: (_, record) => (
        <AddressInformationCard
          currentTransactionTab={currentTransactionDashboardTab}
          data={record}
        />
      ),
      onCell: (record, rowIndex) => ({
        onClick: (event) => {
          //MUST CLEAN EXISTING DATA
          clearExistingData()
          setDealToView(null)
          openSellerTransaction(record)
        },
      }),
    },
    {
      title: "Seller name",
      dataIndex: "seller_fullname",
      key: "seller_fullname",
      onCell: (record, rowIndex) => ({
        onClick: (event) => {
          //MUST CLEAN EXISTING DATA
          clearExistingData()
          setDealToView(null)
          openSellerTransaction(record)
        },
      }),
    },
    {
      title: "Listed at",
      dataIndex: "listing_price",
      key: "listing_price",
      onCell: (record, rowIndex) => ({
        onClick: (event) => {
          //MUST CLEAN EXISTING DATA
          clearExistingData()
          setDealToView(null)
          openSellerTransaction(record)
        },
      }),
    },
    {
      title: "Listing status",
      dataIndex: "list_status",
      key: "list_status",
      render: (_, record) => (
        <SelectStatus
          type="transactionStatus"
          data={record}
          optionListStatus={listingStatus}
          loading={gettingStatuses}
          currentStatus={record.list_status}
          defautlStatus={record.list_status}
          disabledStatuses={["incomplete"]}
          handleStatusChange={(value) => {
            handleRowListingStatusChange(value, record)
          }}
        />
      ),
    },
    {
      title: "Agent",
      dataIndex: "agent_name",
      key: "agent_name",
      render: (_, record) => (
        <AgentName
          data={record}
        />
      ),
      onCell: (record, rowIndex) => ({
        onClick: (event) => {
          //MUST CLEAN EXISTING DATA
          clearExistingData()
          setDealToView(null)
          openSellerTransaction(record)
        },
      }),
    },
    {
      title: "Listing start",
      dataIndex: "listing_start_date",
      key: "listing_start_date",
      onCell: (record, rowIndex) => ({
        onClick: (event) => {
          //MUST CLEAN EXISTING DATA
          clearExistingData()
          setDealToView(null)
          openSellerTransaction(record)
        },
      }),
    },
    {
      title: "On market",
      dataIndex: "listing_on_market",
      key: "listing_on_market",
      onCell: (record, rowIndex) => ({
        onClick: (event) => {
          //MUST CLEAN EXISTING DATA
          clearExistingData()
          setDealToView(null)
          openSellerTransaction(record)
        },
      }),
    },
    {
      title: "Listing end",
      dataIndex: "listing_end",
      key: "listing_end",
      onCell: (record, rowIndex) => ({
        onClick: (event) => {
          //MUST CLEAN EXISTING DATA
          clearExistingData()
          setDealToView(null)
          openSellerTransaction(record)
        },
      }),
    },
    {
      title: "Contract submitted date",
      dataIndex: "contract_submitted",
      key: "contract_submitted",
      onCell: (record, rowIndex) => ({
        onClick: (event) => {
          //MUST CLEAN EXISTING DATA
          clearExistingData()
          setDealToView(null)
          openSellerTransaction(record)
        },
      }),
    },
    {
      title: "Contract status",
      dataIndex: "deal_status",
      key: "deal_status",
      render: (_, record) => (
        <ContractStatus
          data={record}
          contractStatus={record.deal_status}
          labelStatus={getContractStatus(record.deal_status)}
        />
      ),
      onCell: (record, rowIndex) => ({
        onClick: (event) => {
          //MUST CLEAN EXISTING DATA
          clearExistingData()
          setDealToView(null)
          openSellerTransaction(record)
        },
      }),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <DeleteTransaction
          data={record}
          currentContractStatus={record.deal_status}
          handleDelete={() => {
            if (record.record_type === LISTING) {
              setDeleteTransactionId(record.id)
              setRecordType(LISTING)
            } else if (record.record_type === CONTRACT) {
              if (record.listing_id) {
                setDeleteTransactionId(record.listing_id)
                setRecordType(LISTING)
              } else {
                setDeleteTransactionId(record.id)
                setRecordType(CONTRACT)
              }
            }
            setDeleteTransactionModal(true)
          }}
        />
      ),
    },
  ]

  return (
    <>
      {openDeleteTransactionModal && (
        <TransactionModal
          height="210px"
          toClose={() => {
            setRecordType(null)
            setDeleteTransactionId(null)
            setDeleteTransactionModal(false)
          }}
        >
          <DeleteModal
            handleDeleteTransaction={deleteTransaction}
            loading={currentTransactionDashboardTab === "buyer" ? (deletingOffer || deletingContract) : (deletingListing || deletingContract)}
            toClose={() => {
              setRecordType(null)
              setDeleteTransactionId(null)
              setDeleteTransactionModal(false)
            }}
          />
        </TransactionModal>
      )}
      <TableWrap>
        <Table
          loading={gettingTransactionData || gettingOfferStatuses || gettingStatuses || processing
            ? {
              indicator: <Loader />,
            }
            : false
          }
          bordered
          columns={currentTransactionDashboardTab === "buyer" ? columnsBuyers : columnsSeller}
          dataSource={transactionDataObject}
          pagination={{
            position: ["bottomCenter"],
            defaultPageSize: 50,
            hideOnSinglePage: true,
            showSizeChanger: false,
            total: getTotalCount(),
            onChange: handlePaginationChange,
          }}
          scroll={{
            x: 2000,
          }}
        />
      </TableWrap>
    </>
  )
}

export default withRouter(TechAssetsContainer(TeamProfileContainer(OffersV2(Listings(TransactionTable)))))
