import React, { Component } from "react"
import { LoadScript } from "services/Utils"
import { Input } from "antd"
import { INTER } from "@fonts"
import colors from "@colors"
import {
  getReadableLocationFromGoogleLocation,
  getReadableLocationFromRadiusApi,
} from "dumbComponents/CRMV2/CRMDashboard/Components/utils"
import { NewTag, Space } from "./Layout"

export default class NewGoogleLocation extends Component {
  constructor() {
    super()

    this.state = {
      locationTags: [],
    }
  }

  loadScript() {
    if (typeof window.google === "undefined") {
      LoadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_APIKEY}&libraries=places`,
        () => {
          this.googleAutoCompleteInitializer()
        }
      )
    } else {
      this.googleAutoCompleteInitializer()
    }
  }

  googleAutoCompleteInitializer() {
    const { types, restrict } = this.props
    const options = {
      types,
    }
    const geoComponents = new window.google.maps.places.Autocomplete(this.inputText, options)

    if (restrict) {
      const cityBounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(restrict.south_west.lat, restrict.south_west.lng),
        new window.google.maps.LatLng(restrict.north_east.lat, restrict.north_east.lng)
      )
      options.strictBounds = true
      options.bounds = cityBounds
      options.componentRestrictions = {}
      options.componentRestrictions.country = restrict.country.short_name.toLowerCase()

      geoComponents.setOptions(options)
    }

    window.google.maps.event.addDomListener(this.inputText, "keydown", (e) => {
      if (e.keyCode === 13) {
        e.preventDefault()
      }
    })

    window.google.maps.event.addListener(geoComponents, "place_changed", () => {
      const place = geoComponents.getPlace()
      const location = {
        place_id: place.place_id,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        south_west: {
          lat: place.geometry.viewport.getSouthWest().lat(),
          lng: place.geometry.viewport.getSouthWest().lng(),
        },
        north_east: {
          lat: place.geometry.viewport.getNorthEast().lat(),
          lng: place.geometry.viewport.getNorthEast().lng(),
        },
        address: place.formatted_address,
        isZipCodeSearch: place.name === place.address_components.find(component => component.types.includes("postal_code"))?.long_name,
      }

      place.address_components.map(component => (location[component.types[0]] = component))
      location.readableLocation = getReadableLocationFromGoogleLocation(location)

      if (this.props.clearInput) {
        this.inputText.value = ""
        this.inputText.focus()
      }

      this.setState(
        prevState => ({
          ...prevState,
          locationTags: [...prevState.locationTags, location],
        }),
        () => {
          this.props.onLocationSelect(this.state.locationTags)
          this.inputText.value = ""
          this.inputText.focus()
        }
      )
    })
  }

  componentDidMount() {
    this.loadScript()

    if (this.props.initialValue && this.props.initialValue.length > 0) {
      this.handleInitialValue()
    }
  }

  referenceHandler = (ref) => {
    this.inputText = ref?.input
  }

  handleRemoveLocation = (idToCompare) => {
    const newLocationTags = [...this.state.locationTags]
    const index = newLocationTags.findIndex(
      location => location.place_id === idToCompare || location.c_locality_id === idToCompare
    )

    newLocationTags.splice(index, 1)

    this.props.onLocationDeselect(newLocationTags)
    this.setState(prevState => ({
      ...prevState,
      locationTags: newLocationTags,
    }))
  }

  handleInitialValue() {
    const newLocationTags = this.props.initialValue.map(location => ({
      ...location,
      readableLocation: getReadableLocationFromRadiusApi(location),
    }))

    this.setState(
      prevState => ({
        ...prevState,
        locationTags: newLocationTags,
      }),
      () => {
        this.props.onLocationSelect(this.state.locationTags)
      }
    )
  }

  render() {
    const { placeholder } = this.props
    const { locationTags } = this.state

    return (
      <>
        <Input
          placeholder={placeholder}
          ref={this.referenceHandler}
          style={{
            borderRadius: "8px",
            border: `1px solid ${colors.new_borderGrey}`,
            color: colors.new_grey400,
            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            fontFamily: INTER,
            fontWeight: 400,
            padding: "10px 14px",
          }}
        />

        {locationTags?.length ? (
          <Space wrap style={{ width: "100%", marginTop: "16px" }}>
            {locationTags.map(item => (
              <NewTag
                closable
                onClose={() => {
                  this.handleRemoveLocation(item.place_id || item.c_locality_id)
                }}
              >
                {item.readableLocation}
              </NewTag>
            ))}
          </Space>
        ) : (
          ""
        )}
      </>
    )
  }
}
