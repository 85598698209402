import React, { useState, useEffect } from "react"
import { checkIfOnboardingBannerIsVisible } from "@ui/Sidebar/index"
import ListingsContainer from "container/Listings"
import TechAssetsContainer from "container/TechAssets"
import TCContainer from "container/Realty/TransactionFormContainer"
import TeamProfileContainer from "container/Realty/TeamProfileContainer"
import OffersV2 from "container/OffersV2"
import moment from "moment"
import cookie from "services/CookieStorage"
import Tabs from "./components/Tabs"
import Filters from "../Filters"
import {
  TabsWrapper,
  DocumentLibWrapper,
} from "../../commonStyles"
import TransactionTable from "./components/TransactionTable/index"
import { PAGINATION_LIMIT } from "../../const"

const TABS = [
  {
    value: "buyer",
    label: "Buyer Transactions",
  },
  {
    value: "seller",
    label: "Seller Transactions",
  },
]

const TransactioTab = ({
  setTransactionDashboardTab,
  currentTransactionDashboardTab,
  fetchTransactionData,
  getOfferStatusMappings,
  fetchContractStatuses,
  constructTransaction,
  transactionQuery,
  fetchTransactionOverview,
  teamData,
}) => {
  const tcInformation = cookie.load("tcInformation")
  const teamLead = teamData?.team_members?.find(member => member.member_type === "Team Lead")
  const teamLeadId = teamLead ? teamLead.agent_id : null
  useEffect(() => {
    if (tcInformation?.is_tc && teamLeadId) {
      const curr = {
        ...transactionQuery,
      }

      Object.keys(curr).forEach((item) => {
        curr[item] = null
      })

      curr.skip = 0
      curr.limit = PAGINATION_LIMIT
      curr.overview = false
      curr.dateFilter = "Select Date"
      curr.clientTransactionType = currentTransactionDashboardTab
      curr.viewType = "view_all"
      curr.agentId = teamLeadId

      constructTransaction({
        query: curr,
      })
      if (currentTransactionDashboardTab == "buyer") {
        getOfferStatusMappings()
      }
      const toDate = moment().unix()
      const fromDate = moment().startOf("year").unix()

      const payload = {
        agentId: teamLeadId,
        clientTransactionType: currentTransactionDashboardTab,
        toDate,
        fromDate,
      }
      fetchTransactionOverview({
        payload,
      })
      fetchContractStatuses()
    }
  }, [currentTransactionDashboardTab, teamLeadId])

  useEffect(() => {
    if (!tcInformation?.is_tc) {
      const curr = {
        ...transactionQuery,
      }

      Object.keys(curr).forEach((item) => {
        curr[item] = null
      })

      curr.skip = 0
      curr.limit = PAGINATION_LIMIT
      curr.overview = false
      curr.dateFilter = "Select Date"
      curr.clientTransactionType = currentTransactionDashboardTab

      constructTransaction({
        query: curr,
      })
      if (currentTransactionDashboardTab == "buyer") {
        getOfferStatusMappings()
      }
      const toDate = moment().unix()
      const fromDate = moment().startOf("year").unix()

      const payload = {
        clientTransactionType: currentTransactionDashboardTab,
        toDate,
        fromDate,
      }
      fetchTransactionOverview({
        payload,
      })
      fetchContractStatuses()
    }
  }, [currentTransactionDashboardTab])
  return (
    <>
      <TabsWrapper>
        <Tabs
          steps={TABS}
          toggleTab={currentTab => (
            setTransactionDashboardTab(currentTab))}
          currentTab={currentTransactionDashboardTab}
        />
        {/* <DocumentLibWrapper>
          <p>View document library</p>
        </DocumentLibWrapper> */}
      </TabsWrapper>
      <Filters currentTransactionType={currentTransactionDashboardTab}/>
      {currentTransactionDashboardTab === "buyer" && (
        <TransactionTable />
      )}
      {currentTransactionDashboardTab === "seller" && (
        <TransactionTable />
      )}

    </>
  )
}

export default ListingsContainer(TechAssetsContainer(OffersV2(TCContainer(TeamProfileContainer(TransactioTab)))))
