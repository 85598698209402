import { createSignalAction, createBaseActionCreator, createActionCreator, createSimpleCreator } from "shared/reduxUtils"

const BASE = "SETTINGS"

export const OPEN_LEAD_INTEGRATION_MODAL = "OPEN_LEAD_INTEGRATION_MODAL"
export const CLOSE_LEAD_INTEGRATION_MODAL = "CLOSE_LEAD_INTEGRATION_MODAL"
export const SHOW_LIST_VIEW = "SHOW_LIST_VIEW"
export const HIDE_LIST_VIEW = "HIDE_LIST_VIEW"

export const fetchAllLeadIntegrationSourcesAction = createSignalAction(BASE, "FETCH_ALL_LEAD_INTEGRATION_SOURCES")
export const updateReferralSettingsAction = createSignalAction(BASE, "UPDATE_REFERRAL_SETTINGS")
export const getUniqueEmailAction = createSignalAction(BASE, "GET_UNIQUE_EMAIL")
export const updateLeadIntegrationSourceAction = createSignalAction(BASE, "UPDATE_LEAD_INTEGRATION_SOURCE")
export const syncGmailAction = createSignalAction(BASE, "SYNC_GMAIL")
export const deactivateGmail = createSignalAction(BASE, "DEACTIVATE_GMAIL")
export const unsubscribeFromEmailAction = createSignalAction(BASE, "UNSUBSCRIBE_FROM_EMAIL")
export const getNotificationSettings = createSignalAction(BASE, "GET_NOTIFICATION_SETTINGS")
export const requestToggleNotificationSetting = createSignalAction(BASE, "REQUEST_TOGGLE_NOTIFICATION_SETTING")
export const requestChangePassword = createSignalAction(BASE, "REQUEST_CHANGE_PASSWORD")
export const requestUpdateDisplayEmail = createSignalAction(BASE, "REQUEST_UPDATE_DISPLAY_EMAIL")
export const fetchIntroValuesAction = createSignalAction(BASE, "FETCH_INTRO_VALUES")
export const fetchSMSTemplatesAction = createSignalAction(BASE, "FETCH_SMS_TEMPLATES")
export const updateSMSTemplateAction = createSignalAction(BASE, "UPDATE_SMS_TEMPLATES")
export const getSMSTemplateLegendsAction = createSignalAction(BASE, "GET_LEGENDS")
export const toggleAppointmentSchedulingAction = createSignalAction(BASE, "TOGGLE_APPOINTMENT_SCHEDULING")
export const getCurrentAppointmentSchedulingAction =
 createSignalAction(BASE, "GET_CURRENT_STATUS_FOR_APPOINTMENT_SCHEDULING")
export const submitCalendlyLinkAction = createSignalAction(BASE, "SUBMIT_CALENDLY_LINK")
export const triggerEmailRequestAction = createSignalAction(BASE, "TRIGGER_EMAIL_REQUEST")
export const getBrokerAddonsAction = createSignalAction(BASE, "GET_SUBSCRIBED_ADDONS")

// Promotion Actions
export const fetchSubscriptionInviteAction = createSignalAction(BASE, "FETCH_SUBSCRIPTION_PROMO_INVITE")
export const sendNewSubscriptionPromoInviteAction = createSignalAction(BASE, "SEND_NEW_SUBSCRIPTION_PROMO_INVITE")
export const resendNewSubscriptionPromoInviteAction = createSignalAction(BASE, "RESEND_NEW_SUBSCRIPTION_PROMO_INVITE")
export const triggerCrossSellAnalyticsAction = createSignalAction(BASE, "TRIGGER_CROSS_SELL_ANALYTICS")

// Radius Phone Number
export const fetchRadiusPhoneNumberAction = createSignalAction(BASE, "FETCH_RADIUS_PHONE_NUMBER")

export const openLeadIntegrationModalAction = createActionCreator(OPEN_LEAD_INTEGRATION_MODAL)
export const closeLeadIntegrationModalAction = createActionCreator(CLOSE_LEAD_INTEGRATION_MODAL)
export const showListViewAction = createActionCreator(SHOW_LIST_VIEW)
export const hideListViewAction = createActionCreator(HIDE_LIST_VIEW)
export const toggleSubscriptionCancelModal = createBaseActionCreator(BASE, "TOGGLE_SUBSCRIPTION_CANCEL_MODAL")
export const toggleNotificationSettingDisplay = createBaseActionCreator(BASE, "TOGGLE_NOTIFICATION_SETTING_DISPLAY")
export const toggleNotificationSetting = createBaseActionCreator(BASE, "TOGGLE_NOTIFICATION_SETTING")
export const toggleIntegrationDisplay = createBaseActionCreator(BASE, "TOGGLE_INTEGRATION_DISPLAY")
export const toggleLeadIntegrationErrorModal = createBaseActionCreator(BASE, "TOGGLE_LEAD_INTEGRATION_ERROR_MODAL")
export const toggleUserSettingsErrorModal = createBaseActionCreator(BASE, "TOGGLE_USER_SETTINGS_ERROR_MODAL")
export const changeLeadTypeAction = createSimpleCreator(BASE, "CHANGE_LEAD_TYPE")
export const setLeadTypeAction = createSimpleCreator(BASE, "SET_LEAD_TYPE")
export const getCurrentAgentTypeAction = createSimpleCreator(BASE, "GET_CURRENT_AGENT_TYPE")
export const setCurrentAgentTypeAction = createSimpleCreator(BASE, "SET_CURRENT_AGENT_TYPE")
export const checkCurrentAgentTypeAction = createSimpleCreator(BASE, "CHECK_CURRENT_AGENT_TYPE")
export const setCurrentLeadIdAction = createSimpleCreator(BASE, "SET_CURRENT_LEAD_ID")
export const handleToggleForSMSModalAction = createSimpleCreator(BASE, "HANDLE_SMS_MODAL")
export const handleEditCurrentTemplateAction = createSimpleCreator(BASE, "HANDLE_CURRENT_EDITABLE_TEMPLATE")
export const saveToStoreCurrentTemplateAction = createSimpleCreator(BASE, "STORE_CURRENT_EDITED_TEMPLATE")
export const generateAPITokenAction = createSignalAction(BASE, "GENERATE_API_TOKEN")
export const reGenerateAPITokenAction = createSignalAction(BASE, "RE_GENERATE_API_TOKEN")
export const saveStripeTokenAction = createSignalAction(BASE, "SAVE_STRIPE_TOKEN")
export const getCardDetailsAction = createSignalAction(BASE, "GET_CARD_DETAILS")

// Muiltiple Admins
export const saveMultipleEmailsAndPhoneAction = createSignalAction(BASE, "SAVE_MULTIPLE_PHONE_AND_EMAIL")

// Lead Preference
export const fetchAssistNotesAction = createSignalAction(BASE, "FETCH_ASSIST_NOTES")
export const fetchAssistPreferenceAction = createSignalAction(BASE, "FETCH_ASSIST_PREFERENCE")
export const toggleAssistNotesModalAction = createSimpleCreator(BASE, "TOGGLE_ASSIST_NOTES_EDIT")
export const toggleAssistPreferenceEditAction = createSimpleCreator(BASE, "FETCH_ASSIST_PREFERENCE_EDIT")
export const saveAssistPreferenceAction = createSignalAction(BASE, "SAVE_ASSIST_PREFERENCE")
export const saveAssistNotesActions = createSignalAction(BASE, "SAVE_ASSIST_NOTES")

export const verifyAuthTokenForStripeAction = createSignalAction(BASE, "VERIFY_AUTH_TOKEN")
export const deleteAccountAction = createSignalAction(BASE, "DELETE_ACCOUNT")
export const sendAddCreditCardLinkV2Action = createSignalAction(BASE, "SEND_VER_LINK")

export const saveStripeSubscriptionAction = createSignalAction(BASE, "SAVE_STRIPE_SUBSCRIPTION")

export const getClientSecretAction = createSignalAction(BASE, "GET_CLIENT_SECRET")

export const fetchFinancingDocumentsAction = createSignalAction(BASE, "FETCH_FINANCING")
