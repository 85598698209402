import React from "react";
import styled from "styled-components";
import colors from "@colors"
import { INTER } from "@fonts"

const NextButton = styled.a`
  width: 135px;
  border-radius: 8px;
  border: 1px solid var(--Primary-100, #e0e7ff);
  background: var(--Primary-50, #eef2ff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04),
    0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  padding: 10px 16px;
  display:flex;
  height:40px;
      justify-content: space-between;
    line-height:1.25 !important;
  span {
    color: #5a5ff2;
    font-size:14px;
    font-weight:500;
    font-family: ${INTER};
  }
`;
const Wrap = styled.div`
display:flex;
    justify-content: end;

`

const NeedHelpButton = () => {
    return (
      <Wrap className="">
                <NextButton
      id="custom_intercom"
    >
      <img src={`${CLOUDFRONT}/need-help.svg`} alt="need help icon" />
      <span className="questions-link">Need help?</span>
    </NextButton>
      </Wrap>

  );
};

export default NeedHelpButton;
