import React, { PureComponent } from "react"
import styled from "styled-components"
import Container from "container/Leaderboard"
import LeaderboardTab from "dumbComponents/Leaderboard/LeaderboardTab"
import colors from "@colors"
import root from "window-or-global"
import TeamProfileContainer from "container/Realty/TeamProfileContainer"
import AppContainer from "container/App"
import Header from "dumbComponents/RealtyV3/ContactDashboard/Components/Header"
import LeaderboardList from "dumbComponents/Leaderboard/components/LeaderboardList"
import InfiniteScroll from "react-infinite-scroller"
import { throttle } from "lodash"
import { format } from "date-fns"
import FilterIcon from "images/Filter.svg"
import {
  unixToDate,
  getFirstAndLastDateFromMonth,
  getFirstAndLastDateFromYear,
  checkForUserObject,
  initiatePendo,
} from "services/Utils"
import {
  MONTH_OPTIONS,
  YEAR_OPTIONS,
  MOBILE_BREAK_POINT,
} from "shared/consts"
import DayPickerInput from "react-day-picker/DayPickerInput"
import cookie from "services/CookieStorage"
import Select from "react-select"
import Button from "@ui/Button"
import Loader from "@ui/Loader"
import { LeaderBoardHeading, LeaderBoardFontStyle } from "dumbComponents/Leaderboard/components/LeaderBoardStyledComponent"
import { ATC } from "@fonts"
import FilterPanel from "./components/FilterPanel"

const user = checkForUserObject("user")

export const EVENT_DATE_FORMAT = "MMM DD YYYY"

const Wrap = styled.div`
  padding: 0 28px;
`
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  ${props => props.marginBottom && `
    margin-bottom: ${props.marginBottom}px
  `}
  ${props => props.marginTop && `
    margin-top: ${props.marginTop}px
  `}
  margin-bottom: -6px;
`

const LeftHeaderSection = styled.div`
  display: flex;
  flex: 3;
`
const Heading = styled(LeaderBoardHeading)`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 26px;
  line-height: 16px;
  color: ${colors.black};
  margin: 0px;
  margin-left: 5px;
  @media (max-width: ${MOBILE_BREAK_POINT}) {
    display: none;
  }
`

const RightHeaderSection = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  align-items: center;
  flex-wrap: wrap;
  p {
    width: 100px;
  }
`

const TextInputSmall = styled.input`
  width: 212.7px;
  height: 49px;
  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  border: 0px;
  padding-left: 8px;
  font-weight: 200;
  font-size: 16px;
`

const InnerWrap = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 20px;
  cursor: pointer;

  .date-pick-leaderboard, .DayPickerInput {
    input {
      height: 35px;
      padding-left: 5px;
      font-weight: 300;
      font-size: 13px;
      border: 0.5px solid #303030;

      &::placeholder {
        font-size: 12px;
      }
    }
  }
`

const StyledButton = styled(Button)`
  ${LeaderBoardFontStyle}
  border: none;
  background: transparent;
  cursor: pointer;
  width: max-content;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: ${colors.suvaGray};
`

const LeaderboardListHeader = styled.div`
  ${LeaderBoardFontStyle}
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: ${colors.suvaGray};
  text-align: right;
`

const LeaderboardListWrap = styled.div`
  padding: 130px 0;
  padding-top: 0px;
`
const MessageBoxContainer = styled.div`
  padding: 42px 0px;
  button {
    height: max-content;
    border-radius: 100px;
    padding: 16px;
    background: ${colors.whiteBlueFaded};
    cursor: pointer;
    display: block;
    color: ${colors.black};
    border: none;
    position: relative;
    margin-top: 24px;
  }
`

const MessageBox = styled.div`
  background: ${colors.deepPurple};
  border-radius: 16px;
  padding: 32px;
  color: #F8F8F8;
  h1 {
    ${LeaderBoardFontStyle}
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    color: #F8F8F8;
    margin: 0;
  }
`
const ButtonText = styled.h2`
  ${LeaderBoardFontStyle}
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: ${colors.black};
  margin: 0;
`

const DateRangeFilterButton = styled.button`
  height: 35px;
  cursor: pointer;
  border: 1px solid #303030;
  background: transparent;
  color: #303030;
  width: 111px;
  font-weight: 300;
  font-size: 12px;
`

const SelectWrap = styled.div`
  margin-right: 20px;
  .filter_option {
    width: 150px;
    font-size: 16px;
    margin-top: 10px;
    > div {
      border: 1px solid ${colors.rockBlue};
      border-radius: 4px;
    }
  }
`

const FilterWrap = styled.div`
  justify-content: space-between;
  display: flex;
`

const LeaderboardListContainer = styled.div`

`
const HeaderContainerWrap = styled.div`
  margin-bottom: 28px;
  margin-left: -28px;
`

const LeaderboardComponent = ({
  leaderBoardListsData,
  leaderBoardListsFetching,
  isResetLeaderBoardLists,
  activeCategory,
  activeTab,
  showMonth,
  fromDate,
  toDate,
  getLeaderBoardLists,
  history,
  formatString,
}) => {
  // Extract commonly used values
  const response = leaderBoardListsData?.response || []
  const isDone = leaderBoardListsData?.isDone
  const emptyListObj = leaderBoardListsData?.emptyListObj
  const isResetting = isResetLeaderBoardLists?.isFetching
  const isFetchingMore = leaderBoardListsFetching && response.length > 0

  // Loading states handler
  const LoadingList = () => (
    <LeaderboardListWrap>
      {[...Array(4)].map((_, index) => (
        <LeaderboardList
          key={`loading-${index}`}
          isLoading
          rank={8}
          totalCount={8}
          withBackground={false}
          agentName="Paityn Korsgaard"
          imgSrc="https://s3-alpha-sig.figma.com/img/2870/07b0/b962391318ed38b3293f378a105596ec?Expires=1627862400&Signature=PQRDPIwgUAEmddkM6ZpVDXt4yFYPpLsVdXncgZHO3OSS4~KdFcXa~LPlTKmRt2rhCKX3YTuP787TyXtfjJAYCNWq4g84PcIzAKoVR2IU2OYOqdUZ~UchfjW5Za77B9dm53OIzaXRwh4gzyKO4rOHKkulambXuaiFgxy26tGjyyX7WQSVle4z8rbtmhtjXAAj3Drl4j-EbgdfwiLQM4ZsI7xBiFJhuxLpBj5sfvAHrTFqNdt1y2oeufQHkLAu8qzB2QM0O5mCS9~v4AKXBmquuKFol3sIkNh7psEUpkejZuNDw490wjOVhftW7kBOetQ~PWxOHmbCcRCWeIrX8~vemw__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
        />
      ))}
    </LeaderboardListWrap>
  )

  // Empty state handler
  const EmptyState = () => (
    <MessageBoxContainer>
      <MessageBox>
        <h1>{emptyListObj.message}</h1>
        {emptyListObj.buttonText && (
          <button onClick={() => history.push(emptyListObj.buttonNavigation)} type="button">
            <ButtonText>{emptyListObj.buttonText}</ButtonText>
          </button>
        )}
      </MessageBox>
    </MessageBoxContainer>
  )

  // Infinite scroll handler
  const handleLoadMore = throttle(() => {
    getLeaderBoardLists({
      duration: showMonth ? "monthly" : "",
      type: activeTab,
      metric: activeCategory,
      limit: 10,
      skip: response.length,
      from_date: fromDate || null,
      to_date: toDate || null,
    })
  }, 4000)

  // Initial loading state
  if (leaderBoardListsFetching) {
    return <Loader top="0" bottom="0" style={{ margin: "100px auto" }} />
  }

  // No data state
  if (!leaderBoardListsFetching && (!response || response.length === 0)) {
    return emptyListObj ? <EmptyState /> : <div />
  }

  return (
    <div>
      <LeaderboardListHeader>{formatString(activeCategory)}</LeaderboardListHeader>

      {!isResetting && (
        <LeaderboardListContainer>
          <LeaderboardListWrap>
            <InfiniteScroll
              hasMore={!leaderBoardListsFetching && !isFetchingMore}
              loadMore={!isDone ? handleLoadMore : undefined}
              threshold={4000}
            >
              {response.map((stats, index) => (
                <LeaderboardList
                  key={`${stats.agent.firstname}-${index}`}
                  rank={index + 1}
                  totalCount={stats.count}
                  withBackground={index === 0}
                  agentName={`${stats.agent.firstname || ""} ${stats.agent.lastname || ""}`}
                  imgSrc={stats.agent.agent_image}
                  profileUrl={stats.agent.profile_url}
                />
              ))}
              {isFetchingMore && <LoadingList />}
            </InfiniteScroll>
          </LeaderboardListWrap>
        </LeaderboardListContainer>
      )}

      {isResetting && <LoadingList />}
    </div>
  )
}

class Leaderboard extends PureComponent {
  state = {
    isFilterOpen: false,
    activeTab: "agents",
    activeCategory: "closed_deals",
    fromDate: null,
    toDate: null,
    month: { value: -1, label: "All Months" },
    year: { value: new Date().getFullYear(), label: `${new Date().getFullYear()}` },
  }

  async componentDidMount() {
    const { user: userData, getLeaderBoardLists, getCashbackProgram, getTeamDetailsResponse } = this.props
    const { activeTab, activeCategory } = this.state
    const {
      data: teamDetails,
    } = getTeamDetailsResponse || {}
    const isBrokerageTeamMember = teamDetails && teamDetails.id
    const { firstDateEpoch, lastDateEpoch } = getFirstAndLastDateFromYear()

    const classNameForDataLayer = root && root.localStorage.getItem("variant_className") || ""

    const isPartOfBrokerageTeam = userData
    && parseInt(userData.brokerageTeamId, 10) > 0 || userData.isBrokerageTeamMember
    const userType = userData.isSubscribedToBrokerPlan
      ? isPartOfBrokerageTeam ? "brokerage_team" : "brokerage_agent" : "network"

    if (root.dataLayer) {
      const payload = {
        event: "user_data",
        email: user.email,
        user_type: userType,
      }

      if (classNameForDataLayer) {
        payload.class_name = classNameForDataLayer
      }
      await root.dataLayer.push(payload)
    }

    initiatePendo({
      id: user.id,
      email: user.email,
      userType,
    })

    this.setState({
      fromDate: firstDateEpoch,
      toDate: lastDateEpoch,
    })
    getLeaderBoardLists({
      metric: activeCategory,
      type: isBrokerageTeamMember ? "my_team" : activeTab,
      limit: 10,
      skip: 0,
      refresh: true,
      from_date: firstDateEpoch || null,
      to_date: lastDateEpoch || null,
      firstTimeCallback: (result) => {
        if(result !== 0 && isBrokerageTeamMember)  { // I know its a duplicate call
          this.setState({
            activeTab: isBrokerageTeamMember ? "my_team" : activeTab,
          })
          getLeaderBoardLists({
            metric: activeCategory,
            type: isBrokerageTeamMember ? "my_team" : activeTab,
            limit: 10,
            skip: 0,
            refresh: true,
            from_date: firstDateEpoch || null,
            to_date: lastDateEpoch || null,
          })
          return
        }
        if (isBrokerageTeamMember && result === 0) {
          getLeaderBoardLists({
            metric: activeCategory,
            type: activeTab,
            limit: 10,
            skip: 0,
            refresh: true,
            from_date: firstDateEpoch || null,
            to_date: lastDateEpoch || null,
          })
          // this.setTabActive("agents")
        }
      },
    })
  }

  handleThisMonth = () => {
    const { showMonth, activeTab, activeCategory } = this.state
    const { getLeaderBoardLists } = this.props
    this.setState({
      showMonth: !showMonth,
    })
    getLeaderBoardLists({
      duration: !showMonth ? "monthly" : "",
      type: activeTab,
      metric: activeCategory,
      limit: 10,
      skip: 0,
      refresh: true,
    })
  }

  toggleFilterComponent = (isFilterOpen) => {
    this.setState({
      isFilterOpen,
    })
  }

  setActiveCategory = (activeCategory) => {
    this.setState({
      activeCategory,
    })
  }

  setTabActive = (activeTab) => {
    this.setState({
      activeTab,
    })
  }

  setFilter = (filterType, filterValue) => {
    this.setState({
      [filterType]: filterValue,
    })
    if (["month", "year"].includes(filterType)) {
      const { getLeaderBoardLists, getTeamDetailsResponse } = this.props
      const {
        data: teamDetails,
      } = getTeamDetailsResponse || {}
      const {
        month,
        year,
        activeTab,
        activeCategory,
      } = this.state
      const isBrokerageTeamMember = teamDetails && teamDetails.id

      const yr = filterType === "year" ? filterValue.value : year.value
      const mn = filterType === "month" ? filterValue.value : month.value
      const { firstDateEpoch, lastDateEpoch } = mn === -1 ? getFirstAndLastDateFromYear(yr)
        : getFirstAndLastDateFromMonth(mn, yr)

      console.log(firstDateEpoch, lastDateEpoch)

      this.setState({
        fromDate: firstDateEpoch,
        toDate: lastDateEpoch,
      }, () => {
        getLeaderBoardLists({
          metric: activeCategory,
          type: activeTab,
          limit: 10,
          skip: 0,
          refresh: true,
          from_date: firstDateEpoch || null,
          to_date: lastDateEpoch || null,
        })
      })
    }
  }

  formatString = str => str.split("_").join(" ")

  render() {
    const {
      user,
      leaderBoardLists,
      getLeaderBoardLists,
      isResetLeaderBoardLists,
      history,
    } = this.props
    const {
      showMonth,
      isFilterOpen,
      activeTab,
      activeCategory,
      fromDate,
      toDate,
      month,
      year,
    } = this.state
    const {
      isFetching: leaderBoardListsFetching, data: leaderBoardListsData, isFetchingMore,
    } = leaderBoardLists
    const name = user.firstname ? `${user.firstname} ${user.lastname}` : ""

    return (
      <Wrap>
        <HeaderContainerWrap>
          <Header name={name} mailId="brokeragesupport@radiusagent.com" hideSupport />
        </HeaderContainerWrap>
        <LeaderboardTab />
        <HeaderContainer marginBottom={24} marginTop={20}>
          <LeftHeaderSection>
            <Heading>
              Radius Agent Leaderboard
            </Heading>
          </LeftHeaderSection>
          <RightHeaderSection>
            <InnerWrap
              style={{
                gap: "15px",
                flexDirection: "row",
              }}
            >
              <FilterWrap>
                <SelectWrap>
                  {/* <Label>Month</Label> */}
                  <Select
                    className="filter_option"
                    defaultValue={month}
                    options={MONTH_OPTIONS}
                    onChange={(item) => { this.setFilter("month", item) }}
                  />
                </SelectWrap>
                <SelectWrap>
                  {/* <Label>Year</Label> */}
                  <Select
                    className="filter_option"
                    defaultValue={year}
                    options={YEAR_OPTIONS}
                    onChange={(item) => { this.setFilter("year", item) }}
                  />
                </SelectWrap>
              </FilterWrap>
            </InnerWrap>
            <InnerWrap onClick={() => { this.toggleFilterComponent(true) }}>
              <img src={FilterIcon} alt="filter" />
              <StyledButton>Filter Leaderboard</StyledButton>
            </InnerWrap>
            <FilterPanel
              month={month.value}
              year={year.value}
              isFilterOpen={isFilterOpen}
              response={{}}
              filterMap={[]}
              showMonth={showMonth}
              currentResourceSearchValue={{}}
              setActiveCategory={this.setActiveCategory}
              setTabActive={this.setTabActive}
              activeTab={activeTab}
              activeCategory={activeCategory}
              toggleFilterComponent={() => { this.toggleFilterComponent(false) }}
            />
          </RightHeaderSection>
        </HeaderContainer>
        <LeaderboardComponent
          leaderBoardListsData={leaderBoardListsData}
          leaderBoardListsFetching={leaderBoardListsFetching}
          isResetLeaderBoardLists={isResetLeaderBoardLists}
          activeCategory={activeCategory}
          activeTab={activeTab}
          showMonth={showMonth}
          fromDate={fromDate}
          toDate={toDate}
          getLeaderBoardLists={getLeaderBoardLists}
          history={history}
          formatString={this.formatString}
        />
      </Wrap>
    )
  }
}
export default TeamProfileContainer(Container(AppContainer((Leaderboard))))
