import React, { useState, useRef } from "react"
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
} from "react-image-crop"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import RangeSlider from "@ui/RangeSlider"
import cookie from "services/CookieStorage"
import colors from "@colors"
import styled from "styled-components"
import Modal from "@ui/Modal"
import Box from "@ui/BuilderComponents/BoxV2/index"
import { isStringUrl } from "services/Utils"
import "react-image-crop/dist/ReactCrop.css"
import { canvasPreview } from "./canvasPreview"
import { useDebounceEffect } from "./debouceFunction"

/*
  If the component detects an image, modal shows.
  On selecting the image, action dispatched should trigger the modal.
  Inside modal user should be able to crop to their requirement.
  Once confirmed upon saving the image should be uploaded.
  Post upload the signed url should be saved to update team profile.
*/

const StyledButton = styled.button`
  height: 32px;
  width: 74px;
  border-radius: 36px;
  padding: 8px;
  border: none;
  cursor: pointer;
  background-color: ${colors.new_primary};
  color: ${colors.white};
  font-family: ATC;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  width: 100%;
  height: 40px;

  ${props => props.inverse && `
    background-color: ${colors.white};
    color: ${colors.new_primary};
    border: 1px solid ${colors.new_primary};
  `}

  ${props => props.disabled && `
    cursor: not-allowed;
    background-color: ${colors.greyBackground};
    color: ${colors.white};
    border: 1px solid ${colors.new_primary};
  `}
`

const Para = styled.p`
  font-family: ATC;
  font-size: ${props => props.fs || "34px"};
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
`

const Label = styled.label`
  margin-bottom: 15px;
  font-size: 20px;
  font-family: 'ATC';
`

const Image = styled.img`
  max-width: 820px;
  ${props => props.isLogo && `
    max-height: 217px;
  `}
`

const SliderWrap = styled.div``

const CurrentImageP = styled.p`
  display: none;

  ${props => props.isRoomsModal && `
    display: block;
    color: #303030;
    text-align: right;
    margin: 20px 0px;
  `}
`

const CurrentImageWrap = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  height: 350px;
  width: 100%;

  background-image: url(${props => props.image});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`

const centerAspectCrop = (
  mediaWidth,
  mediaHeight,
  aspect,
) => centerCrop(
  makeAspectCrop(
    {
      unit: "%",
      width: 100,
    },
    aspect,
    mediaWidth,
    mediaHeight,
  ),
  mediaWidth,
  mediaHeight,
)

const getAspectRatio = (type) => {
  switch (type) {
    case "teamLogo":
      return 4 / 3
    case "teamBanner":
      return 48 / 12
    // case "roomsPhoto":
    //   return undefined
    default:
      return 4 / 3
  }
}

const PhotoEditor = ({
  image,
  uploadAction,
  closeModal,
  currentCroppedType,
  isUploading,
  isDeleteActive,
  toggleDeleteModal,
  isRoomsModal,
  minScale = 0,
}) => {
  /*Image Ref*/
  const imgRef = useRef(null)

  const [currentImage, setImage] = useState(null)

  const [scale, setScale] = useState(1)

  /*Final Saved State*/
  const [finalState, setFinalState] = useState(null)

  /*Canvas Ref*/
  const previewCanvasRef = useRef(null)

  const [canvasConfig, setCanvasConfig] = useState({
    height: null,
    width: null,
  })

  /*User clicked to Edit*/
  const [isUserEdit, setUserEdit] = useState(false)

  /*Crop Complete State*/
  const [completedCrop, setCompletedCrop] = useState(false)

  const [crop, setCrop] = useState(undefined)

  const [aspect, setAspect] = useState(getAspectRatio(currentCroppedType))

  const getName = (type) => {
    switch (type) {
      case "teamLogo":
        return "Team Logo"
      case "teamBanner":
        return "Team Banner"
      case "imageOne":
      case "imageTwo":
      case "imageThree":
        return "Team Images"
      default:
        return "Image"
    }
  }

  const onImageLoaded = (e) => {
    if (getAspectRatio(currentCroppedType)) {
      switch (e) {
        case "teamLogo":
          setCanvasConfig(logoConfig)
          break
        case "teamBanner":
          setCanvasConfig(bannerConfig)
          break
        case "imageOne":
        case "imageTwo":
        case "imageThree":
          setCanvasConfig(otherImageConfig)
          break
        case "roomsPhoto":
          setCanvasConfig(roomsImage)
          break
        default:
          break
      }
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, getAspectRatio(currentCroppedType)))
    }
  }

  const onCropComplete = (currCrop, pixelCrop) => {
    setCompletedCrop(currCrop)
    imageFactory(crop, pixelCrop)
  }

  const onCropChange = (_, percentCrop) => {
    if (currentCroppedType === "roomsPhoto") {
      setAspect(undefined)
    }
    setCrop(percentCrop)
  }

  const imageFactory = async (currCrop, pixelCrop) => {
    if (imgRef && currCrop.width && currCrop.height) {
      const { croppedImageFile } = await getCroppedImg(
        imgRef,
        pixelCrop,
        "newFile.png",
      )
      setFinalState(croppedImageFile)
    }
  }

  const bannerConfig = {
    height: 202,
    width: 816,
  }

  const logoConfig = {
    height: 142,
    width: 142,
  }

  const otherImageConfig = {
    height: 240,
    width: 256,
  }

  const roomsImage = {
    height: 1728,
    width: 1848,
  }

  const getCroppedImg = (currImgRef, pixelCrop, fileName) => {
    const canvas = document.createElement("canvas")
    const ctx = canvas.getContext("2d")
    const imageNow = currImgRef.current

    if (!ctx) {
      throw new Error("No 2d context")
    }

    ctx.drawImage(
      imageNow,
      0,
      0,
      imageNow.width,
      imageNow.height,
      0,
      0,
      imageNow.width,
      imageNow.height,
    )

    return new Promise((resolve) => {
      canvas.toBlob((rawBlob) => {
        const blob = rawBlob
        blob.name = fileName || "some-file-png.png"
        resolve({
          croppedImageFile: blob,
        })
      }, "image/png")
    })
  }

  const handleUpload = () => {
    const canvasElement = previewCanvasRef.current
    const img = canvasElement.toDataURL()
    const file = dataURItoBlob(img)
    if (uploadAction && finalState) {
      uploadAction({
        type: currentCroppedType,
        file,
        name: `team_image_${currentCroppedType}_${cookie.load("brokerageTeamId")}_.png`,
      })
    }
  }
  const dataURItoBlob = (dataURI) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString
    if (dataURI.split(",")[0].indexOf("base64") >= 0) {
      byteString = atob(dataURI.split(",")[1])
    } else {
      byteString = unescape(dataURI.split(",")[1])
    }
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0]
    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length)
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    return new Blob([ia], { type: mimeString, name: "new-file.png" })
    // separate out the mime component
  }

  const handleRangeChange = (e) => {
    const currentRange = e.target.value
    setScale(currentRange)
  }

  try {
    useDebounceEffect(
      async () => {
        if (
          completedCrop.width
          && completedCrop.height
          && imgRef.current
          && previewCanvasRef.current
        ) {
          // We use canvasPreview as it's much faster than imgPreview.
          canvasPreview(
            imgRef.current,
            previewCanvasRef.current,
            completedCrop,
            scale,
          )
        }
      },
      100,
      [completedCrop, scale],
    )
  } catch (e) {
    //fail silently
  }

  const handleEdit = () => {
    if (isStringUrl(image) || !image) {
      const input = document.createElement("input")
      input.type = "file"
      input.onchange = (e) => {
        const file = e.target.files[0]
        const reader = new FileReader()
        reader.addEventListener("load", () => {
          setImage(reader.result.toString())
          return true
        })
        reader.readAsDataURL(file)
        if (file) {
          setUserEdit(true)
        }
      }
      input.click()
    } else {
      setUserEdit(true)
    }
  }

  const handleDelete = () => {
    //toggle Confirmation modal
    toggleDeleteModal(true)
  }

  return (
    <Modal
      show={image}
      disableOutSideClose={currentCroppedType === "roomsPhoto"}
      modalVersion={2}
      toClose={closeModal}
      dialogStyles={{
        width: "max-content",
        maxWidth: currentCroppedType === "teamBanner" ? "unset" : "820px",
        minWidth: "820px",
      }}
      contentStyles={{
        background: "#F9F9F7",
      }}
      iconStyles={{
        top: "11px",
        width: "27px",
      }}
      modalStyles={{
        background: `${colors.white}`,
      }}
    >
      <Box
        borderBottom="1px solid #E5E5E5"
        paddingLargeDesktop="0px 0px 20px 0px"
        paddingMinDesktop="0px 0px 20px 0px"
        paddingTablet="0px 0px 20px 0px"
        paddingMobile="0px 0px 20px 0px"
      >
        <Para>{getName(currentCroppedType)}</Para>
      </Box>
      {isUserEdit && (currentImage || image) ? (
        <ReactCrop
          src={currentImage || image}
          crop={crop}
          minWidth="100px"
          minHeight="100px"
          onComplete={onCropComplete}
          onChange={onCropChange}
          keepSelection="true"
          aspect={aspect}
        >
          <Box
            displayStyle="flex"
            alignItems="center"
            width="100%"
            height="max-content"
          >
            <img
              crossOrigin="anonymous"
              ref={imgRef}
              alt="Crop me"
              src={currentImage || image}
              onLoad={onImageLoaded}
              style={currentCroppedType === "teamLogo" ? {
                margin: "0 auto",
                maxWidth: "275px",
                transform: `scale(${scale})`,
              } : {
                width: "-webkit-fill-available",
                transform: `scale(${scale})`,
              }}
            />
          </Box>
        </ReactCrop>
      ) : (
        <Box
          displayStyle="flex"
          justifyContent="center"
          flexDirection="column"
          customDivStyling={{
            margin: "25px 0px 25px 0px",
          }}
        >
          <CurrentImageWrap
            image={image}
          />
          {/* <Image isLogo={currentCroppedType === "teamLogo"} src={image} alt="editable_img" /> */}
          <CurrentImageP isRoomsModal={isRoomsModal}>Current Image</CurrentImageP>
        </Box>
      )}
      <Box
        margin="20px 0px 0px 0px"
        displayStyle="none"
      >
        {Boolean(completedCrop) && (
          <Box
            displayStyle="hidden"
          >
            <Para fs="20px">Image Preview</Para>
            <canvas
              id="canvasIdNew"
              ref={previewCanvasRef}
              style={{
                border: "0.5px dotted #395139",
                objectFit: "contain",
                width: completedCrop.width,
                height: completedCrop.height,
              }}
            />
          </Box>
        )}
      </Box>
      {isUserEdit ? (
        <Box
          displayStyle="flex"
          flexDirection="column"
          justifyContent="flex-end"
          flexGap="20px"
          padding="20px 0px 0px 0px"
          paddingLargeDesktop="20px 0px 0px 0px"
          paddingMinDesktop="20px 0px 0px 0px"
          paddingTablet="20px 0px 0px 0px"
          paddingMobile="20px 0px 0px 0px"
        >
          <SliderWrap>
            <Label htmlFor="scale-img">Scale</Label>
            <RangeSlider
              name="scale-img"
              value={scale}
              type="range"
              step="0.1"
              min={minScale || 0}
              max="10"
              onChange={e => handleRangeChange(e)}
            />
          </SliderWrap>
          <StyledButton onClick={handleUpload}>
            {isUploading ? (
              <ThreeDotLoader color="#fff" />
            ) : "Save Changes"}
          </StyledButton>
        </Box>
      ) : (
        <Box
          displayStyle="flex"
          flexDirection="row"
          justifyContent="center"
          padding="20px 0px 0px 0px"
          paddingLargeDesktop="20px 0px 0px 0px"
          paddingMinDesktop="20px 0px 0px 0px"
          paddingTablet="20px 0px 0px 0px"
          paddingMobile="20px 0px 0px 0px"
          flexGap="15px"
        >
          {isDeleteActive && (
            <StyledButton inverse onClick={handleDelete}>
              Delete
            </StyledButton>
          )}
          <StyledButton onClick={handleEdit}>
            {isRoomsModal ? "Upload New Image" : "Edit"}
          </StyledButton>
        </Box>
      )}
    </Modal>
  )
}
export default PhotoEditor
