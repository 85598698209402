import React, { useEffect, useState, useCallback } from "react";
import TeamProfileContainer from "container/Realty/TeamProfileContainer";
import CRMV2Container from "container/CRM";
import CookiesStorage from "services/CookieStorage";
import { debounce } from "lodash";
import { Input, Select, Button, Dropdown, Space, Checkbox } from "antd";
import { DownOutlined, UpOutlined, SearchOutlined } from "@ant-design/icons";
import { StyledInput, StyledButton, StyledSelect } from "./styles";
import TeamFilterComponent from "dumbComponents/CRMV2/CRMDashboard/Components/TeamFilterComponent/index.js";

const { Option } = Select;

const FilterComponent = ({
  getClientFiltersResponse,
  getTeamDetailsResponse,
  currentFilters,
  updateFilterQueries,
  getClientTransactions,
}) => {
  const [showFilters, setShowFilters] = useState(false);
  const [teamValue, setTeamValue] = useState("");
  const [sortValue, setSortValue] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const isFilterActive = () => currentFilters
    && (currentFilters.statuses.length > 0
    || currentFilters.types.length > 0
    || currentFilters.sortBy
    || currentFilters.isFavourites
    || currentFilters.team_member_id
    || currentFilters.text
    )

  const getFiltersOptions = () => {
    const { data: teamDetails } = getTeamDetailsResponse || {};
    const user = CookiesStorage.load("user") || {};
    const teamNames = [];

    teamNames.push({
      value: "view_mine",
      label: "View my Clients",
    });

    teamNames.push({
      value: "view_all",
      label: "View all Clients",
    });

    teamNames.push({
      value: "view_all_team_members",
      label: "View team member's Clients",
    });

    if (teamDetails && teamDetails.team_members) {
      teamDetails.team_members
        .filter((td) => td.agent_id !== user.id)
        .forEach((tm) => {
          console.log(tm, "here");
          teamNames.push({
            key: `${tm.agent_id}::${tm.firstname}`,
            label: `${tm.firstname} ${tm.lastname} Clients`,
            value: tm.agent_id,
          });
        });
    }
    return teamNames || [];
  };

  const teamMemberSelection = (e, key) => {
    const getLabel = (type) => {
      const isAgentId = type != "view_mine"
      && type !== "view_all"
      && type !== "view_all_team_members"
      let temp = type
      let agentId = null

      if(isAgentId) {
        type = "id"
        agentId = parseInt(temp, 10)
      }
      
      switch(type) {
        case "view_mine":
          return "My Leads"
        case "view_all":
          return "All Leads"
        case "view_all_team_members":
          return "All Team Member's Leads"
        case "id": 
          const label = getFiltersOptions()
            .filter((item) => item.value === agentId)[0].label
          return label
        default:
          return "My Leads"
      }
    }

    const selectedValue = e
    const updated = currentFilters
    updated[key] = selectedValue
    updated.view_type_label = getLabel(selectedValue)
    updated.view_type = selectedValue !== "view_all" 
    && selectedValue !== "view_mine" 
    && selectedValue !== "view_all_team_members" 
    ? "view_specific_team_members" : selectedValue

    updateFilterQueries({
      filters: updated,
    })
    setTeamValue(selectedValue)
  }

  const sortOnChange = (e, key) => {
    const selectedValue = e
    const updated = currentFilters
    updated[key] = selectedValue

    if(selectedValue === "view_all")
    updateFilterQueries({
      filters: updated,
    })
    setSortValue(selectedValue)
  }

  const getOptions = (options) =>
    options &&
    options.map((item) => ({
      value: item.value,
      label: item.display_name || item.value,
    }));
  const { data: filterSkeleton } = getClientFiltersResponse || {};

  const onChange = (checkedValues, key) => {
    const updated = {
      ...currentFilters,
      [key]: [checkedValues],
    }

    updateFilterQueries({
      filters: updated,
    })
  }

  const handleSearchClear = () => {
    const updated = {
      ...currentFilters,
      statuses: [],
    }

    updateFilterQueries({
      filters: updated,
    })
  }

  const handleClear = () => {
    const cleared = {
      types: [],
      sortBy: null,
      statuses: [],
      isFavourite: null,
      team_member_id: null,
      view_type_label: "My Leads",
      view_type: "view_mine",
    }
    setTeamValue("");
    setSortValue("");
    setSearchValue("");
    updateFilterQueries({
      filters: cleared,
    })
    getClientTransactions()
    toggleFilterDrawer(false)
  }

  const debouncedClientSearch = useCallback(
    debounce((value, key) => {
      const updated = {
        ...currentFilters,
        [key]: value,
      }
      updateFilterQueries({
        filters: updated,
      })
    }, 500),
    [currentFilters]
  );

  const handleClientSearch = (e) => {
    setSearchValue(e.target.value);
    debouncedClientSearch(e.target.value, "text");
  };

  useEffect(() => {
    getClientTransactions({
      currentFilters,
    })
  }, [currentFilters, teamValue, sortValue]);

  return (
    <div>
      <div style={{ display: "flex", gap: "8px", marginBottom: "8px" }}>
        {/* Search by Clients */}
        <StyledInput prefix={<SearchOutlined style={{marginLeft: "15px", fontSize: "18px"}}/>} placeholder="Search by clients" allowClear onChange={(e) => handleClientSearch(e)} value={searchValue}/>

        {/* Dropdown: Team & Personal Clients */}
        <TeamFilterComponent
          firstValueOption={{
            key: "My Leads",
            label: "My Active Clients",
            value: "view_mine",
          }}
          secondValueOption={{
            key: "Team Leads",
            label: "Team Clients",
            value: "view_all_team_members",
          }}
          firstValueLabel="My Active Clients"
          secondValueLabel="Team Clients"
          defaultInitialName="Team & Personal Clients"
        />

        {/* Filters Toggle Button */}
        <StyledButton onClick={() => setShowFilters(!showFilters)} style={{width: "150px", color: "#373758"}}>
          Filters {showFilters ? <UpOutlined style={{marginLeft: "10px"}}/> : <DownOutlined style={{marginLeft: "10px"}}/>}
        </StyledButton>
      </div>

      {/* Filters Section */}
      {showFilters && (
        <div style={{ display: "flex", gap: "8px" }}>
          {/* Select Status */}
          <StyledSelect
            mode="multiple"
            showArrow
            placeholder="Select status"
            allowClear
            value={currentFilters ? currentFilters.statuses[0] : null}
            options={getOptions(filterSkeleton.clientTransactionFilters)}
            onChange={e => {
              if (e.length === 0) {
                handleSearchClear();
              }
              else {
                onChange(e, "statuses");
              }
            }}
            maxTagCount={1}
          ></StyledSelect>

          {/* Type */}
          <StyledSelect
            placeholder="Type"
            style={{ flex: 1 }}
            value={currentFilters.types}
            options={getOptions(filterSkeleton.leadTypes)}
            onChange={e => onChange(e, "types")}
          ></StyledSelect>

          {/* Sort By */}
          <StyledSelect
            placeholder="Sort by"
            style={{ flex: 1 }}
            options={filterSkeleton && filterSkeleton.clientTransactionSort}
            onChange={e => sortOnChange(e, "sortBy")}
            value={currentFilters.sortBy}
          ></StyledSelect>

          {/* Select Teams */}
          <StyledSelect
            placeholder="Select teams"
            style={{ flex: 1 }}
            options={getFiltersOptions()}
            value={currentFilters.team_member_id}
            onChange={e => teamMemberSelection(e, "team_member_id")}
          ></StyledSelect>

          {/* buttons */}
          {isFilterActive() && <StyledButton onClick={handleClear} style={{color: "#F05152", backgroundColor: "transparent", border: "none", fontWeight: "600"}}>Clear</StyledButton>}
        </div>
      )}
    </div>
  );
};

export default TeamProfileContainer(CRMV2Container(FilterComponent));
