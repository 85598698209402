import {
  delay,
} from "redux-saga"
import {
  takeLatest,
  call,
  put,
  takeEvery,
  select,
  throttle,
} from "redux-saga/effects"
import { get } from "lodash"
import { authCheck } from "services/auth"
import {
  getFirstCharacterCapital,
  isSuccess,
  saveUserObjectToCookie,
  UrlSearchParams,
  unixToDate,
  clearCookiesOnLogout,
  initFreshChat,
  isMobile,
  LoadScript,
  initiatePendo,
} from "services/Utils"
import {
  addDays,
  differenceInHours,
  differenceInMinutes,
} from "date-fns"
import { getOnboardingNavigateRoute } from "dumbComponents/Auth/authUtils"
import LogRocket from "services/LogRocket"
import root from "window-or-global"
import cookie from "services/CookieStorage"
import createToast from "@ui/Toast"
import * as AppSelector from "container/App/selector"
import * as ProfileAPI from "container/Profile/api"
import * as MarketplaceAPIs from "container/Marketplace/api"
import * as ProfileAction from "container/Profile/action"
import * as OnboardingAction from "container/OnboardingSetup/actions"
import * as ReferralActions from "container/Referral/actions"
import { getPopupDetailsAction, fetchState, toggleNetworkAgentPopupAction } from "container/TechAssets/actions"
import { getItemFromLocalStorage, removeItemFromLocalStorage, setItemInLocalStorage } from "shared/utils"
import * as AppActions from "./actions"
import { getTeamDetailsAction, getTCAgentAssignedAction, getTeamDetailsForLogoAction } from "../Realty/actions"
import {
  fetchUserProfileAPI,
  updateLeadAgreementAPI,
  getPopUpScreenAPI,
  closePopUpScreenAPI,
  fetchRedirectionLocationAPI,
  recordUserSessionAPI,
  userSeenPopupAPI,
  promotionalCTAClickAPI,
  submitPartnerInterestAPI,
  submitTrasactionalInterestAPI,
  submitBrokerInterestAPI,
  getAgentsForRealtyPageAPI,
  getDashboardLinksAPI,
  registerDeviceFCMAPI,
  getSidebarMenuUpdatesAPI,
  sessionTrackingAPI,
  getNotificationMappingAPI,
  setNotificationChoiceAPI,
  logoutUserAPI,
  updateIncompleteProfilePostSignUpAPI,
  fetchInviteStatusAPI,
  postInviteAPI,
  saveOccupationChoiceAPI,
  saveHappinessMeterAPI,
  setCurrentOnboardingStateAPI,
  triggerSlackNotificationAPI,
  submitInterestAPI,
  getTeamsForRealtyPageAPI,
  getReferralCumulativeDataAPI,
  trackReferralHomePageAPI,
  getStateBannerAPI,
  createBrokerMemberInviteLogAPI,
  appDownloadSMSAPI,
} from "./api"
const vwoSDK = require("vwo-node-sdk")

const saveAssociationsToCookies = (agent) => {
  const { associations = [] } = agent
  associations.forEach((association) => {
    if (association.associationShortName !== "crs") {
      const keyName = `is${getFirstCharacterCapital(association.associationShortName)}`
      cookie.save(keyName, true)
    }
  })
}

const OldTitle = ""

const isLogged = authCheck()

function* appMount(action) {
  try {
    const {
      triggerDataLayerPush,
      classNameForDataLayer,
      callback,
    } = action.data || {}
    const res = yield call(fetchUserProfileAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      const invitedUser = cookie.load("team_member_invite")
      if (invitedUser && invitedUser.brokerage_team_id && invitedUser.agent_id) {
        yield call(createBrokerMemberInviteLogAPI, {
          brokerage_team_id: parseInt(invitedUser.brokerage_team_id, 10),
          agent_id: parseInt(invitedUser.agent_id, 10),
        })
        cookie.remove("team_member_invite")
      }
      const leadContractSigned = cookie.load("leadAgreementSigned")
      const user = cookie.load("user")
      saveAssociationsToCookies(response)
      if (root.bugsnagClient) {
        root.bugsnagClient.user = response
      }
      const {
        leadAgreementSigned,
        referralManager,
        isCrsDesignee,
        allowLeadAgreement,
        isaLeadAgreementSigned,
        gmail_email,
        id: agentId,
        office_short_state_name,
        office_city,
        profile_name,
        isaManager,
        isCardInactive,
        onboardingQuizInfo,
        citiesServed,
        isBrokerageTeamMember,
        account_type,
        isUserOnboarded,
        brokerageTeamId,
        isSubscribedToBrokerPlan,
        tcInformation,
        email,
        state,
        has_production_info,
        role,
        brokerageTeamIdForLogo,
      } = response

      console.log("triggerDataLayerPush", triggerDataLayerPush)
      if (triggerDataLayerPush) {
        const isPartOfBrokerageTeam = parseInt(brokerageTeamId, 10) > 0 || isBrokerageTeamMember
        const userType = isSubscribedToBrokerPlan
          ? isPartOfBrokerageTeam ? "brokerage_team" : "brokerage_agent" : "network"

        if (root.dataLayer) {
          const payload = {
            event: "user_data",
            email,
            user_type: userType,
          }

          if (classNameForDataLayer) {
            payload.class_name = classNameForDataLayer
          }

          yield root.dataLayer.push(payload)
        }

        // initiatePendo({
        //   id: user.id,
        //   email,
        //   userType,
        // })
      }

      const history = get(action, "data.history")

      const isRealEstateAgent = account_type === "real_estate_agent"
      if (isCardInactive) {
        yield put(AppActions.togglebannerPaddingAction.call(118))
      }
      saveUserObjectToCookie({
        referralManager,
        isCrsDesignee,
        allowLeadAgreement,
        isaLeadAgreementSigned,
        gmail_email,
        agentId,
        isaManager,
        isBrokerageTeamMember,
        brokerageTeamId,
        // tcInformation: { is_tc: true },
        tcInformation,
        brokerageTeamIdForLogo,
      })

      if (brokerageTeamId) {
        yield put(getTeamDetailsAction.request({
          id: brokerageTeamId,
        }))
        if (agentId) {
          yield put(fetchState.request({
            agentId,
          }))
        }
      }

      if (brokerageTeamIdForLogo) {
        yield put(getTeamDetailsForLogoAction.request({
          id: brokerageTeamIdForLogo,
        }))
      }

      if (agentId) {
        yield put(getTCAgentAssignedAction.request({
          agentId,
        }))
      }
      // yield put(getPopupDetailsAction.request())
      const ignoreList = []
      const fakeSavedTime = root.localStorage.getItem("leadAgreementSignedTime")
      if (fakeSavedTime) {
        const diffTimeMins = differenceInMinutes(new Date(), unixToDate(fakeSavedTime / 1000))
        if (diffTimeMins > 5) {
          cookie.save("leadAgreementSigned", leadAgreementSigned)
          root.localStorage.removeItem("leadAgreementSignedTime")
        } else {
          ignoreList.push("leadAgreementSignedTime")
          console.log("Ignoring Backend Value for 5 mins")
        }
      } else {
        cookie.save("leadAgreementSigned", leadAgreementSigned)
      }
      cookie.save("allowLeadAgreement", allowLeadAgreement)
      cookie.save("isaLeadAgreementSigned", isaLeadAgreementSigned)
      const updatedUser = {
        ...user,
        office_short_state_name,
        office_city,
        profile_name,
      }
      saveUserObjectToCookie({ user: updatedUser })
      if (leadContractSigned !== "signed") {
        saveUserObjectToCookie({
          leadAgreementSigned,
          user,
        }, ignoreList)
      }
      yield put(AppActions.fetchUserInfoAction.success(response))
      yield put(AppActions.appReadyAction())
      if (root.heap) {
        root.heap.identify(agentId)
        root.heap.addUserProperties({ email: user.email })
      }
      if (root._hsq) {
        root._hsq.push({
          email: user.agentId,
          id: agentId,
        })
      }
      initFreshChat(user)
      if (callback) {
        callback()
      }
      if (!isUserOnboarded && onboardingQuizInfo && onboardingQuizInfo.showOnboardingQuiz && isRealEstateAgent) {
        const onboardingRoute = getOnboardingNavigateRoute(onboardingQuizInfo.showStepKey)
        if (onboardingRoute && history) {
          cookie.save("redirectUrl", root.location.href)
          history.push(onboardingRoute)
          return
        }
      }
      if (!(citiesServed && citiesServed.length) && isRealEstateAgent && history) {
        cookie.save("redirectUrl", root.location.href)
        history.push("/onboarding/tell-about-yourself")
        return
      }
      yield put(AppActions.recordUserSessionAction.request())
      yield put(OnboardingAction.checkPaymentBannerAction.request({ email }))

      if (isSubscribedToBrokerPlan) {
        yield put(AppActions.getReferralCumulativeDataAction.request())
        LogRocket.initialize()
      } else {
        console.log("removed state banner")
        // yield put(AppActions.getStateAnnouncementAction.request())
        const isLocation = (root.location.href.includes("community") || root.location.href.includes("dashboard"))
        const isValue = (!state || !account_type || !has_production_info)
        const showPopup = (isValue && isLocation)
        if (showPopup) {
          yield put(toggleNetworkAgentPopupAction.call(true))
        } else {
          yield put(AppActions.getStateAnnouncementAction.request())
        }
      }

      console.log("user", user)

      // only load freshchat for logged in users
      // LoadScript("https://js-na1.hs-scripts.com/7498349.js", () => {
      //   if (root.HubSpotConversations) {
      //     root.HubSpotConversations.widget.load()
      //   }
      // })

      const fullname = `${user.firstname} ${user.lastname || ""}`.trim()

      root.intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: "iv9ssqkn",
        name: fullname, // Full name
        user_id: user.agent_id, // a UUID for your user
        email: user.email, // the email for your user
        created_at: response.createdAt,
        custom_launcher_selector:'#custom_intercom'
      }

      if (root.HubSpotConversations) {
        root.HubSpotConversations.widget.remove()
      }

      if (root.Intercom && !root.Intercom.boot) {
        root.Intercom("boot", {
          ...root.intercomSettings,
        })
      } else if (root && root.Intercom && root.Intercom.boot) {
        root.Intercom("update", {
          ...root.intercomSettings,
        })
      }
    }
  } catch (e) {
    const isUnauthorised = e.status === 401
    const response = e.data
    yield put(AppActions.fetchUserInfoAction.failure({
      ...response,
      isUnauthorised,
    }))
    yield put(AppActions.hideFreshchatOnMobileAction.call())
    initFreshChat()
    yield put(AppActions.appReadyAction())
    //root.location.href = "https://www.radiusagent.com"
  }
}

function* handleFreshChatHide() {
  try {
    const ATTEMPTS = 50
    /*
      Ok, this might look a bit hacky, but this think works!
      Problem: Hubspot is initialized from a lot of places on codebase. Therefore,
      I was not able to find global place where in a disbale it conditionally.Bcuz we're
      only removing it for mobile.

      Solution: Run a loop for 5 seconds with a delay, with closes all the triggers for this.
    */
    for (let i = 0; i <= ATTEMPTS; i += 1) {
      yield delay(1000)
      if (root.HubSpotConversations) {
        console.log("Removing Chat Bot")
        root.HubSpotConversations.widget.remove()
      }
    }
  } catch (error) {
    console.log("Error: ", error)
  }
}

function* saveNewProfileImage(action) {
  try {
    const user = cookie.load("user")
    user.agent_image = action.data
    cookie.save("user", user)
  } catch (e) {
    // handle Error
    console.error(e)
  }
}

function* syncUserCookie(action) {
  try {
    const user = cookie.load("user")
    const {
      firstname,
      lastname,
    } = action.data
    const updatedUser = {
      ...user,
      firstname,
      lastname,
    }
    saveUserObjectToCookie({ user: updatedUser })
  } catch (e) {
    // ignore error
  }
}

function* showNewFeeds() {
  try {
    // OldTitle = root.document.title
    // root.document.title = `New Posts | ${OldTitle}`
  } catch (e) {
    // handle exception
  }
}

function* pushRedirect(action) {
  try {
    cookie.save("redirectUrl", action.data)
  } catch (error) {
    console.warn(error)
  }
}

function* pullRedirect() {
  try {
    const redirectUrl = cookie.load("redirectUrl")
    if (redirectUrl) {
      cookie.remove("redirectUrl")
      root.location = redirectUrl
    }
  } catch (error) {
    console.warn(error)
  }
}

function* hideNewFeed() {
  try {
    // root.document.title = OldTitle
    const res = yield call(updateLeadAgreementAPI)
  } catch (e) {
    // handle exception
  }
}

function* updateReferralAgreement(action) {
  try {
    const res = yield call(updateLeadAgreementAPI)
    if (isSuccess(res)) {
      yield put(AppActions.updateReferralAgreementAction.success(res))
      const callBackURL = UrlSearchParams("callbackUrl") || `${APP_URL}/referrals/marketplace`
      root.localStorage.setItem("isDocuSignSignedSuccessfully", callBackURL)
      root.close()
    } else {
      console.warn(res)
    }
  } catch (e) {
    console.log(e)
  }
}

function* updateUserProfile(action) {
  const { payload, toastSuccess, toastError } = action.data
  const currentUserObject = yield select(AppSelector.getUserObjectFromStore)
  try {
    yield put(AppActions.localUpdateUserObject.action(payload))
    const res = yield call(ProfileAPI.saveProfileInfoAPI, payload)
    if (isSuccess(res)) {
      yield put(AppActions.requestUpdateUserObject.success())
      if (toastSuccess) {
        createToast(toastSuccess)
      }
    } else {
      yield put(AppActions.requestUpdateUserObject.failure(currentUserObject))
      createToast(toastError, "error")
    }
  } catch (e) {
    yield put(AppActions.requestUpdateUserObject.failure(currentUserObject))
    createToast(toastError, "error")
  }
}

function* collectPhoneNumber(action) {
  try {
    const user = yield select(state => state.app.user)
    const payload = {
      ...user,
      phone: action.data,
    }
    const res = yield call(ProfileAPI.saveProfileInfoAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AppActions.fetchUserInfoAction.request())
      yield put(AppActions.collectPhoneNumberAction.success())
      yield put(ProfileAction.saveProfileUpdateAction.success(response))
    }
  } catch (error) {
    yield put(AppActions.collectPhoneNumberAction.failure(error))
    yield put(AppActions.allowUserToClosePhoneCollectionModalAction.call(true))
  }
}

function* handleRedirection(action) {
  try {
    const { data: { id } } = action
    const res = yield call(fetchRedirectionLocationAPI, id)
    if (isSuccess(res)) {
      const { response } = res.data
      root.parent.location.href = response.link
      yield put(AppActions.getRedirectionLocationAction.success(response))
    }
  } catch (e) {
    yield put(AppActions.getRedirectionLocationAction.failure(e))
  }
}

function* handleAppReady() {
  const { pathname } = root.location
  const currentPath = pathname
  const user = yield select(state => state.app.user)
  const {
    isUserOnboarded,
    id,
    status,
    showIsaPopup,
  } = user
  // const flaggedPath = (currentPath.indexOf("/community") > -1) || (currentPath.indexOf("/radius-assist") > -1)
  const flaggedPath = (currentPath.indexOf("/community") > -1)
}
function* getPopupScreens() {
  try {
    const res = yield call(getPopUpScreenAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AppActions.getPopUpScreenAction.success(response))
    }
  } catch (error) {
    yield put(AppActions.getPopUpScreenAction.failure(error))
  }
}

function* handlePopupClose(action) {
  try {
    // remove the popup data from store, this will close the modal
    yield put(AppActions.getPopUpScreenAction.clear())
    //In the background, tell the BE about the action permformed the user.
    const res = yield call(closePopUpScreenAPI, action.data)
    if (isSuccess(res)) {
      //const { response } = res.data
    }
  } catch (error) {
    // Close the modal irrespective, of failed api
    yield put(AppActions.getPopUpScreenAction.clear())
    console.error(error)
  }
}

export function* confirmAction(action) {
  if (action.data) {
    cancelAction()
    return action.data
  }
  return false
}

export function* cancelAction() {
  yield put(AppActions.hideConfirmModalAction.call())
  return false
}

function* recordUserSession() {
  try {
    const currentDate = new Date()
    const lastSession = unixToDate(cookie.load("lastSession") / 1000)
    const timeDiff = differenceInHours(currentDate, lastSession)
    const diffInMins = differenceInMinutes(currentDate, lastSession)
    if (lastSession && timeDiff < 24) {
      console.log("NOT recording a new session")
    } else {
      const res = yield call(recordUserSessionAPI, { templateIds: [2] })
      yield put(AppActions.recordUserSessionAction.success(res))
      cookie.save("lastSession", currentDate.getTime())
    }
  } catch (error) {
    yield put(AppActions.recordUserSessionAction.failure(error))
  }
}

function* userSeenPopup(action) {
  try {
    const res = yield call(userSeenPopupAPI, action.data)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AppActions.userSeenPopupAction.success(response))
    }
  } catch (error) {
    yield put(AppActions.userSeenPopupAction.failure(error))
  }
}

function* handleBannerToggle() {
  cookie.save("bannerClosed", true)
  root.document.querySelector(".main-navbar").style.top = "0px"
  yield put(AppActions.togglebannerPaddingAction.call(70))
  yield put(AppActions.hideHeaderBannerAction.call())
}

function* handlePartnerFormInterest(action) {
  try {
    const res = yield call(submitPartnerInterestAPI, action.data)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AppActions.submitPartnerInterestAction.success(response))
    }
  } catch (error) {
    yield put(AppActions.submitPartnerInterestAction.failure(error))
  }
}

function* handleBrokerFormInterest(action) {
  try {
    const res = yield call(submitBrokerInterestAPI, action.data)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AppActions.submitBrokerInterestAction.success(response))
    }
  } catch (error) {
    yield put(AppActions.submitBrokerInterestAction.failure(error))
  }
}

function* handlePromotionalCTAClick(action) {
  try {
    const { source } = action.data
    const payload = {
      interest_page: source,
    }
    const res = yield call(promotionalCTAClickAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AppActions.promotionalPopUpCTAClckedAction.success(response))
    }
  } catch (error) {
    yield put(AppActions.promotionalPopUpCTAClckedAction.failure(error))
  }
}

function* handleTransctionalInterest(action) {
  try {
    const res = yield call(submitTrasactionalInterestAPI, action.data)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AppActions.submitTrasactionInterestAction.success(response))
    }
  } catch (error) {
    yield put(AppActions.submitTrasactionInterestAction.failure(error))
  }
}

function* handleRecruitAndEarnTrack(action) {
  try {
    const res = yield call(MarketplaceAPIs.claimUnverifiedLeadAPI, action.data)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AppActions.trackRecruitAndEarnAction.success(response))
    }
  } catch (error) {
    yield put(AppActions.trackRecruitAndEarnAction.failure(error))
  }
}

function* handleGetAgents(action) {
  try {
    const { query = "" } = action.data || {}
    const res = yield call(getAgentsForRealtyPageAPI, query)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AppActions.getAgentsActions.success(response))
    }
  } catch (error) {
    yield put(AppActions.getAgentsActions.failure(error))
  }
}

function* handleGetTeams(action) {
  try {
    const { query = "" } = action.data || {}
    const res = yield call(getTeamsForRealtyPageAPI, query)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AppActions.getTeamsActions.success(response))
    }
  } catch (error) {
    yield put(AppActions.getTeamsActions.failure(error))
  }
}

function* handleSideMenuUpdateFetch() {
  try {
    const res = yield call(getSidebarMenuUpdatesAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AppActions.getSideMenuUpdatesAction.success(response))
    }
  } catch (error) {
    yield put(AppActions.getSideMenuUpdatesAction.failure(error))
  }
}

function* handleDashboardLinks() {
  try {
    const res = yield call(getDashboardLinksAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AppActions.getDashboardLinkAction.success(response))
    }
  } catch (error) {
    yield put(AppActions.getDashboardLinkAction.failure(error))
  }
}

function* handleScrollToElement(action) {
  try {
    const {
      id,
      offset = 0,
      behavior = "smooth",
      left = 0,
    } = action.data
    const $ele = root.document.querySelector(id)
    console.log($ele)
    const payload = {
      top: $ele && $ele.offsetTop ? $ele.offsetTop + offset : offset,
      left,
      behavior,
    }
    root.scrollTo(payload)
  } catch (error) {
    console.log("Something went wrong with scroll.")
    console.warn(error)
  }
}

function* handleFCMDeviceRegister(action) {
  try {
    const {
      token,
    } = action.data
    const payload = {
      gcmToken: token,
    }
    const res = yield call(registerDeviceFCMAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AppActions.registerDeviceForFCMAction.success(response))
    }
  } catch (error) {
    yield put(AppActions.registerDeviceForFCMAction.failure(error))
  }
}

function* handleSessionTracking() {
  try {
    const res = yield call(sessionTrackingAPI)
    if (isSuccess(res)) {
      yield put(AppActions.trackSessionAction.success({}))
    }
  } catch (error) {
    yield put(AppActions.trackSessionAction.failure(error))
  }
}

function* handleNotificationMappingRequest() {
  try {
    const res = yield call(getNotificationMappingAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AppActions.getNotificationReminderMappingAction.success(response))
    }
  } catch (error) {
    yield put(AppActions.getNotificationReminderMappingAction.failure(error))
  }
}

function* handleNotificationChoice(action) {
  try {
    const {
      id,
    } = action.data
    const res = yield call(setNotificationChoiceAPI, id)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AppActions.updateDBWithUserChoiceOnNotificationAction.success(response))
    }
  } catch (error) {
    yield put(AppActions.updateDBWithUserChoiceOnNotificationAction.failure(error))
  }
}

function* handleLogoutUser() {
  try {
    const res = yield call(logoutUserAPI)
    clearCookiesOnLogout()
    if (root.heap) {
      root.heap.resetIdentity()
    }
    root.location.href = `${RADIUS_URL}/signup`
    localStorage.removeItem("listing_type_dropdown")
  } catch (error) {
    if (root.heap) {
      root.heap.resetIdentity()
    }
    clearCookiesOnLogout()
    yield put(AppActions.logoutUserAction.failure(error))
    root.location.href = `${RADIUS_URL}/signup`
  }
}

function* updateIncompleteProfile(action) {
  try {
    const {
      payload,
      history,
      accountType,
    } = action.data
    const res = yield call(updateIncompleteProfilePostSignUpAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AppActions.updateIncompleteProfileAction.success(response))
      yield put(AppActions.fetchUserInfoAction.request())
      if (accountType === "real_estate_agent") {
        history.push("/onboarding/tell-about-yourself")
      } else {
        window.location.href = "/community"
      }
    }
  } catch (error) {
    yield put(AppActions.updateIncompleteProfileAction.failure(error))
  }
}

function* toggleRARFormOpenHandler(action) {
  try {
    if (action.data) {
      root.top.postMessage("isChatModalClose", "*")
    } else {
      root.top.postMessage("isChatModalOpen", "*")
    }
  } catch (error) {
    console.log("error")
  }
}

function* handleFakeSignDocusign(action) {
  try {
    const {
      callback,
    } = action.data
    root.localStorage.setItem("leadAgreementSignedTime", String((new Date()).getTime()))
    cookie.save("leadAgreementSigned", "signed")
    yield put(AppActions.fakeSignDocusignAction.success())
    if (callback) {
      callback()
    }
  } catch (error) {
    yield put(AppActions.fakeSignDocusignAction.failure(error))
  }
}

function* handleFetchInviteStatusRequest() {
  try {
    const res = yield call(fetchInviteStatusAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AppActions.getCurrentReferralInviteStatusAction.success(response))
    }
  } catch (error) {
    yield put(AppActions.getCurrentReferralInviteStatusAction.failure(error))
  }
}

function* handleSubmitInvites(action) {
  try {
    const {
      payload,
      fromFeed,
      isFeedSubmit,
      isOnboarding,
      history,
    } = action.data
    const res = yield call(postInviteAPI, payload)
    //const isAlreadyPosted = yield select(state => state.inviteFeatureReducer.isInviteFeedPostVisible)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AppActions.postEmailInvitesAction.success(response))
      if (isFeedSubmit) {
        yield put(AppActions.toggleSuccessInviteSubmitFromFeed.call())
      } else {
        yield put(AppActions.toggleSuccessInviteSubmitFromModalAction.call(true))
      }

      if (isOnboarding) {
        history.push("/onboarding/successful-onboarding")
      }
    }
  } catch (error) {
    yield put(AppActions.postEmailInvitesAction.failure(error))
  }
}

function* handleSaveOccupation(action) {
  try {
    const {
      payload,
    } = action.data
    const res = yield call(saveOccupationChoiceAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AppActions.saveOccupationTypeAction.success(response))
    }
  } catch (error) {
    console.log(error)
    yield put(AppActions.saveOccupationTypeAction.failure(error))
  }
}

//handleHappinessRange
function* handleHappinessRange(action) {
  try {
    const {
      payload,
    } = action.data
    const res = yield call(saveHappinessMeterAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AppActions.handleHappinessRangeAction.success(response))
    }
  } catch (error) {
    console.log(error)
    yield put(AppActions.handleHappinessRangeAction.failure(error))
  }
}

function* handleOnboardingSteps(action) {
  try {
    const {
      payload,
    } = action.data
    const res = yield call(setCurrentOnboardingStateAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AppActions.setCurrentOnboardingStepAction.success(response))
      yield put(AppActions.triggerSlackNotificationAction.request())
      //window.location.href = "/community"
    }
  } catch (error) {
    console.log(error)
    yield put(AppActions.setCurrentOnboardingStepAction.failure(error))
  }
}

function* handleSlackTrigger() {
  try {
    const res = yield call(triggerSlackNotificationAPI, {})
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AppActions.triggerSlackNotificationAction.success(response))
      window.location.href = "/community"
    }
  } catch (error) {
    console.log(error)
    yield put(AppActions.triggerSlackNotificationAction.failure(error))
  }
}

function* handleApplyInterest(action) {
  try {
    let vwoClientInstance
    let settingsFile
    const {
      payload,
      vwoUserId,
      logInModalClassName,
      vwoClient,
      webClassName,
      callback,
      interestFormObject,
    } = action.data

    const res = yield call(submitInterestAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AppActions.submitInterestFormAction.success(response))
      // yield put(AppActions.toggleApplyModalAction.call(false))
      if (root.dataLayer) {
        yield root.dataLayer.push({
          ...interestFormObject,
          class_name: webClassName,
        })
      }
      if (callback) {
        callback()
        return
      }
      if (payload.source === "Recruit and Earn") {
        const recruitAndEarnId = cookie.load("recruitAndEarnId")
        yield call(trackReferralHomePageAPI, {
          email: payload.email,
          invite_code: recruitAndEarnId,
        })
        yield put(AppActions.submittedRefferalInviteFormAction.call(true))
        return
      }

      if (!isLogged) {
        // yield put(AppActions.toggleSignUpFormAction.call({
        //   bool: true,
        //   logInModalClassName,
        // }))
      } else {
        yield put(AppActions.toggleSuccessModalAction.call(true))
      }

      if (!isLogged && vwoUserId) {
        /**COMMENTED FOR STAGiNG BUILD */
        // vwoSDK.getSettingsFile(713375, "05cfc80f3c77cd47941085366102db33").then((vwoData) => {
        //   settingsFile = vwoData

        //   vwoClientInstance = vwoSDK.launch({
        //     settingsFile,
        //   })
        //   vwoClientInstance.track("Production Campaign", vwoUserId, "conversion", {})
        // })
      }
    }
  } catch (error) {
    console.log("error", error)
    yield put(AppActions.submitInterestFormAction.failure(error))
  }
}

function* handleStateAnnouncementFetch() {
  try {
    const res = yield call(getStateBannerAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AppActions.getStateAnnouncementAction.success(response))
      yield put(AppActions.toggleStateAnnouncementAction.call(true))
    }
  } catch (error) {
    yield put(AppActions.getStateAnnouncementAction.failure(error))
  }
}

function* getReferralData() {
  try {
    const res = yield call(getReferralCumulativeDataAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AppActions.getReferralCumulativeDataAction.success(response))
    }
  } catch (e) {
    yield put(AppActions.getReferralCumulativeDataAction.failure(e))
    createToast(e, "error")
  }
}

function* handleAppDownloadSMS(action) {
  try {
    const { payload, reset } = action.data
    const res = yield call(appDownloadSMSAPI, payload)
    if (isSuccess(res)) {
      yield put(AppActions.setIsAppDownloadSMSFormSubmittedAction.call(true))
      const { response } = res.data

      if (!reset) {
        yield put(AppActions.appDownloadSMSAction.success(response))
      } else {
        yield delay(2000)
        yield put(AppActions.appDownloadSMSAction.success())
      }
    }
  } catch (e) {
    yield put(AppActions.appDownloadSMSAction.failure(e))
    createToast(e, "error")
  }
}

export default function* main() {
  yield takeLatest(AppActions.fetchUserInfoAction.REQUEST, appMount)
  yield takeLatest(AppActions.logoutUserAction.REQUEST, handleLogoutUser)
  yield takeLatest(AppActions.pushRedirectAction.type, pushRedirect)
  yield takeLatest(AppActions.pullRedirectAction.type, pullRedirect)
  yield takeLatest(AppActions.SAVE_NEW_PROFILE_IMAGE, saveNewProfileImage)
  yield takeLatest(AppActions.SYNC_USER_COOKIES, syncUserCookie)
  yield takeLatest(AppActions.SHOW_NEW_FEED_AVAILABLE, showNewFeeds)
  yield takeLatest(AppActions.HIDE_NEW_FEED_AVAILABLE, hideNewFeed)
  yield takeLatest(AppActions.updateReferralAgreementAction.REQUEST, updateReferralAgreement)
  yield takeLatest(AppActions.requestUpdateUserObject.REQUEST, updateUserProfile)
  yield takeLatest(AppActions.collectPhoneNumberAction.REQUEST, collectPhoneNumber)
  yield takeLatest(AppActions.confirmAction.type, confirmAction)
  yield takeLatest(AppActions.cancelAction.type, cancelAction)
  yield takeEvery(AppActions.requestUpdateUserObject.REQUEST, updateUserProfile)
  yield takeEvery(AppActions.collectPhoneNumberAction.REQUEST, collectPhoneNumber)
  yield takeLatest(AppActions.getRedirectionLocationAction.REQUEST, handleRedirection)
  yield takeLatest(AppActions.getPopUpScreenAction.REQUEST, getPopupScreens)
  yield takeLatest(AppActions.APP_READY, handleAppReady)
  yield takeLatest(AppActions.recordUserSessionAction.REQUEST, recordUserSession)
  yield takeLatest(AppActions.userSeenPopupAction.REQUEST, userSeenPopup)
  yield takeLatest(AppActions.toggleHeaderBannerAction.type, handleBannerToggle)
  yield throttle(1000, AppActions.closePopUpAction.type, handlePopupClose)
  yield takeLatest(AppActions.submitPartnerInterestAction.REQUEST, handlePartnerFormInterest)
  yield takeLatest(AppActions.submitBrokerInterestAction.REQUEST, handleBrokerFormInterest)
  yield takeLatest(AppActions.submitTrasactionInterestAction.REQUEST, handleTransctionalInterest)
  yield takeLatest(AppActions.promotionalPopUpCTAClckedAction.REQUEST, handlePromotionalCTAClick)
  yield takeLatest(AppActions.trackRecruitAndEarnAction.REQUEST, handleRecruitAndEarnTrack)
  yield takeLatest(AppActions.getAgentsActions.REQUEST, handleGetAgents)
  yield takeLatest(AppActions.getDashboardLinkAction.REQUEST, handleDashboardLinks)
  yield takeLatest(AppActions.getSideMenuUpdatesAction.REQUEST, handleSideMenuUpdateFetch)
  yield takeLatest(AppActions.scrollToElementAction.type, handleScrollToElement)
  yield takeLatest(AppActions.registerDeviceForFCMAction.REQUEST, handleFCMDeviceRegister)
  yield takeLatest(AppActions.trackSessionAction.REQUEST, handleSessionTracking)
  yield takeLatest(AppActions.getNotificationReminderMappingAction.REQUEST, handleNotificationMappingRequest)
  yield takeLatest(AppActions.updateDBWithUserChoiceOnNotificationAction.REQUEST, handleNotificationChoice)
  yield takeLatest(AppActions.updateIncompleteProfileAction.REQUEST, updateIncompleteProfile)
  yield takeLatest(AppActions.toggleRARFormOpenAction.type, toggleRARFormOpenHandler)
  yield takeLatest(AppActions.fakeSignDocusignAction.REQUEST, handleFakeSignDocusign)
  yield takeLatest(AppActions.getCurrentReferralInviteStatusAction.REQUEST, handleFetchInviteStatusRequest)
  yield takeLatest(AppActions.postEmailInvitesAction.REQUEST, handleSubmitInvites)
  yield takeLatest(AppActions.saveOccupationTypeAction.REQUEST, handleSaveOccupation)
  yield takeLatest(AppActions.handleHappinessRangeAction.REQUEST, handleHappinessRange)
  yield takeLatest(AppActions.setCurrentOnboardingStepAction.REQUEST, handleOnboardingSteps)
  yield takeLatest(AppActions.triggerSlackNotificationAction.REQUEST, handleSlackTrigger)
  yield takeLatest(AppActions.submitInterestFormAction.REQUEST, handleApplyInterest)
  yield takeLatest(AppActions.getTeamsActions.REQUEST, handleGetTeams)
  yield takeLatest(AppActions.getReferralCumulativeDataAction.REQUEST, getReferralData)
  yield takeLatest(AppActions.hideFreshchatOnMobileAction.type, handleFreshChatHide)
  yield takeLatest(AppActions.getStateAnnouncementAction.REQUEST, handleStateAnnouncementFetch)
  yield takeLatest(AppActions.appDownloadSMSAction.REQUEST, handleAppDownloadSMS)
}
