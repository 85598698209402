import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import * as CRMV2Actions from "./actions"

const initState = {
  isUserOverviewDrawerOpen: {
    showUserOverviewDrawer: false,
    agentInfo: {},
  },
  isFilterClientLeadsDrawerOpen: false,
  isAddNewLeadDrawerOpen: false,
  currentPage: "",
  currentTransactionDetails: null,
  currentTopFilter: "status",
  currentFilters: {
    types: [],
    sortBy: null,
    statuses: [],
    text: null,
    isFavourite: null,
    team_member_id: null,
    view_type: "view_mine",
    view_type_label: "My Leads",
  },
  currentClientTransactionID: null,
  isReminderModalOpen: false,
  reminderEditableData: {},
  currentCRMDealId: null,
  modalOpenConfirm: null,
  modalOpenView: null,
  currentClientId: null,
  currentTab: "OVERVIEW",
  currentClientType: "",
  showGoToContract: {
    isShowGoToContract: false,
    result: {},
  },
  isArchiveModalOpen: false,
  isModalForArchivedLeadOpen: false,
  isViewListing: false,
  isViewContract: false,
  isViewOffer: false,
  isAddListing: false,
  isAddOffer: false,
  isEndContract: false,
  isViewListingToContract: false,
  isViewOfferToContract: false,
  cannotConvertContract: false,
  isClosed: false,
  isCreateWarning: false,
  statusValue: "",
  isShowWarning: false,
  isShowInContractWarning: false,
  isShowDeleteNoteModal: false,
  isShowDeleteReminderModal: false,
  ...createDeltaReducer(CRMV2Actions.getClientAction.RESPONSE),
  ...createDeltaReducer(CRMV2Actions.getPropertyTypeAction.RESPONSE),
  ...createDeltaReducer(CRMV2Actions.addClientAction.RESPONSE),
  ...createDeltaReducer(CRMV2Actions.addBuyerRequirementsAction.RESPONSE),
  ...createDeltaReducer(CRMV2Actions.addPropertyDetailsAction.RESPONSE),
  ...createDeltaReducer(CRMV2Actions.getClientTransactionsAction.RESPONSE),
  ...createDeltaReducer(CRMV2Actions.uploadImageAction.RESPONSE),
  ...createDeltaReducer(CRMV2Actions.getBuyerRequirementsAction.RESPONSE),
  ...createDeltaReducer(CRMV2Actions.getPropertyDetailsAction.RESPONSE),
  ...createDeltaReducer(CRMV2Actions.getClientsFilters.RESPONSE),
  ...createDeltaReducer(CRMV2Actions.changeCardOrderAction.RESPONSE),
  ...createDeltaReducer(CRMV2Actions.changeCardStageAction.RESPONSE),
  ...createDeltaReducer(CRMV2Actions.updateClientTransactionAction.RESPONSE),
  ...createDeltaReducer(CRMV2Actions.setReminderAction.RESPONSE),
  ...createDeltaReducer(CRMV2Actions.addNotesAction.RESPONSE),
  ...createDeltaReducer(CRMV2Actions.getNotesAction.RESPONSE),
  ...createDeltaReducer(CRMV2Actions.updateNotesAction.RESPONSE),
  ...createDeltaReducer(CRMV2Actions.deleteNotesAction.RESPONSE),
  ...createDeltaReducer(CRMV2Actions.getTimelineAction.RESPONSE),
  ...createDeltaReducer(CRMV2Actions.getBrokerageTransactionStatusAction.RESPONSE),
  ...createDeltaReducer(CRMV2Actions.createFinancingForTransactionAction.RESPONSE),
  ...createDeltaReducer(CRMV2Actions.updateClientAction.RESPONSE),
  ...createDeltaReducer(CRMV2Actions.fetchClientReminderAction.RESPONSE),
  ...createDeltaReducer(CRMV2Actions.getClientTransactionByIdAction.RESPONSE),
  ...createDeltaReducer(CRMV2Actions.toggleAgentFinancePreferenceAction.RESPONSE),
  ...createDeltaReducer(CRMV2Actions.addCoClientDetailsAction.RESPONSE),
  ...createDeltaReducer(CRMV2Actions.updateCoClientDetailsAction.RESPONSE),
  ...createDeltaReducer(CRMV2Actions.deleteCoClientDetailsAction.RESPONSE),
  ...createDeltaReducer(CRMV2Actions.archiveLeadAction.RESPONSE),
  ...createDeltaReducer(CRMV2Actions.getArchivedLeadAction.RESPONSE),
  ...createDeltaReducer(CRMV2Actions.unArchiveLeadAction.RESPONSE),
  ...createDeltaReducer(CRMV2Actions.deleteClientTransactionAction.RESPONSE),
  ...createDeltaReducer(CRMV2Actions.updateFinancingForTransactionAction.RESPONSE),
  ...createDeltaReducer(CRMV2Actions.createClientTransactionAction.RESPONSE),
  ...createDeltaReducer(CRMV2Actions.updateReminderAction.RESPONSE),
  ...createDeltaReducer(CRMV2Actions.getAgentDetailsAction.RESPONSE),
  ...createDeltaReducer("confirmMoveToCreateContractResponse"),
}

export default function CRMReducer(state = initState, action) {
  switch (action.type) {
    case CRMV2Actions.toggleUserOverviewDrawer.type: {
      return {
        ...state,
        isUserOverviewDrawerOpen: action.data,
      }
    }

    case CRMV2Actions.toggleFilterClientLeadsDrawer.type: {
      return {
        ...state,
        isFilterClientLeadsDrawerOpen: action.data,
      }
    }

    case CRMV2Actions.setModalOpenConfirmAction.type: {
      return {
        ...state,
        modalOpenConfirm: action.data,
      }
    }

    case CRMV2Actions.setModalOpenViewAction.type: {
      return {
        ...state,
        modalOpenView: action.data,
      }
    }

    case CRMV2Actions.toggleAddNewLeadDrawer.type: {
      return {
        ...state,
        isAddNewLeadDrawerOpen: action.data,
      }
    }

    case CRMV2Actions.toggleDrawerPage.type: {
      return {
        ...state,
        currentPage: action.data,
      }
    }

    case CRMV2Actions.setTransactionDetailsAction.type: {
      return {
        ...state,
        currentTransactionDetails: action.data,
      }
    }

    case CRMV2Actions.setCurrentClientIDAction.type: {
      return {
        ...state,
        currentClientId: action.data,
      }
    }

    case CRMV2Actions.setTabAction.type: {
      return {
        ...state,
        currentTab: action.data,
      }
    }

    case CRMV2Actions.setClientTypeAction.type: {
      return {
        ...state,
        currentClientType: action.data,
      }
    }

    case CRMV2Actions.toggleGoToContract.type: {
      const { isShowGoToContract, result } = action.data
      return {
        ...state,
        showGoToContract: {
          isShowGoToContract,
          result,
        },
      }
    }
    case CRMV2Actions.toggleModalForArchivedLeadsAction.type: {
      return {
        ...state,
        isModalForArchivedLeadOpen: action.data,
      }
    }

    case CRMV2Actions.clearUpdatedDataAction.type: {
      return {
        ...state,
        updatePropertyDetailsResponse: {
          data: null,
          isFetching: false,
        },
      }
    }

    case CRMV2Actions.toggleArchiveLeadModalAction.type: {
      return {
        ...state,
        isArchiveModalOpen: action.data,
      }
    }

    case CRMV2Actions.toggleShowInContractAction.type: {
      return {
        ...state,
        isShowInContractWarning: action.data,
      }
    }

    case CRMV2Actions.getClientAction.REQUEST:
    case CRMV2Actions.getClientAction.FAILURE:
    case CRMV2Actions.getClientAction.CLEAR:
    case CRMV2Actions.getClientAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, CRMV2Actions.getClientAction, CRMV2Actions.getClientAction.RESPONSE),
      }
    }

    case CRMV2Actions.confirmMoveToCreateContractAction.REQUEST:
    case CRMV2Actions.confirmMoveToCreateContractAction.FAILURE:
    case CRMV2Actions.confirmMoveToCreateContractAction.CLEAR:
    case CRMV2Actions.confirmMoveToCreateContractAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          CRMV2Actions.confirmMoveToCreateContractAction,
          "confirmMoveToCreateContractResponse",
        ),
      }
    }

    case CRMV2Actions.addClientAction.REQUEST:
    case CRMV2Actions.addClientAction.FAILURE:
    case CRMV2Actions.addClientAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, CRMV2Actions.addClientAction, CRMV2Actions.addClientAction.RESPONSE),
      }
    }

    case CRMV2Actions.getPropertyTypeAction.REQUEST:
    case CRMV2Actions.getPropertyTypeAction.FAILURE:
    case CRMV2Actions.getPropertyTypeAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, CRMV2Actions.getPropertyTypeAction, CRMV2Actions.getPropertyTypeAction.RESPONSE),
      }
    }

    case CRMV2Actions.addBuyerRequirementsAction.REQUEST:
    case CRMV2Actions.addBuyerRequirementsAction.FAILURE:
    case CRMV2Actions.addBuyerRequirementsAction.CLEAR:
    case CRMV2Actions.addBuyerRequirementsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, CRMV2Actions.addBuyerRequirementsAction, CRMV2Actions.addBuyerRequirementsAction.RESPONSE),
      }
    }

    case CRMV2Actions.addPropertyDetailsAction.REQUEST:
    case CRMV2Actions.addPropertyDetailsAction.FAILURE:
    case CRMV2Actions.addPropertyDetailsAction.CLEAR:
    case CRMV2Actions.addPropertyDetailsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, CRMV2Actions.addPropertyDetailsAction, CRMV2Actions.addPropertyDetailsAction.RESPONSE),
      }
    }

    case CRMV2Actions.updatePropertyDetailsAction.REQUEST:
    case CRMV2Actions.updatePropertyDetailsAction.FAILURE:
    case CRMV2Actions.updatePropertyDetailsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, CRMV2Actions.updatePropertyDetailsAction, CRMV2Actions.updatePropertyDetailsAction.RESPONSE),
      }
    }

    case CRMV2Actions.getBrokerageTransactionStatusAction.REQUEST:
    case CRMV2Actions.getBrokerageTransactionStatusAction.FAILURE:
    case CRMV2Actions.getBrokerageTransactionStatusAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, CRMV2Actions.getBrokerageTransactionStatusAction, CRMV2Actions.getBrokerageTransactionStatusAction.RESPONSE),
      }
    }

    case CRMV2Actions.getClientTransactionsAction.REQUEST:
    case CRMV2Actions.getClientTransactionsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, CRMV2Actions.getClientTransactionsAction, CRMV2Actions.getClientTransactionsAction.RESPONSE),
      }
    }

    case CRMV2Actions.getClientTransactionsAction.SUCCESS: {
      let finalData = {}
      let allTransactions = []
      const {
        client_transactions: updatedClientTransaction,
        client_transactions_count,
        offerStage,
        dragDropColumns: updatedDragDropColumns,
        allTransactions: updatedAllTransactions,
      } = action.data
      const {
        client_transactions: currentClientTransaction,
        dragDropColumns: currentDragDropColumns,
        allTransactions: currentAllTransactions,
      } = state.getClientTransactionResponse.data || {}
      if (state && state.getClientTransactionResponse.data && offerStage && currentClientTransaction[offerStage]) {
        currentClientTransaction[offerStage] = currentClientTransaction[offerStage].concat(updatedClientTransaction[offerStage])
        currentDragDropColumns[offerStage] = currentDragDropColumns[offerStage].concat(updatedDragDropColumns[offerStage])
        allTransactions = { ...currentAllTransactions, ...updatedAllTransactions }
        finalData = {
          client_transactions: currentClientTransaction,
          client_transactions_count,
          dragDropColumns: currentDragDropColumns,
          allTransactions,
        }
      } else {
        return {
          ...state,
          ...handleDeltaReducer(state, action, CRMV2Actions.getClientTransactionsAction, CRMV2Actions.getClientTransactionsAction.RESPONSE),
        }
      }
      return {
        ...state,
        ...handleDeltaReducer(state, action, CRMV2Actions.getClientTransactionsAction, CRMV2Actions.getClientTransactionsAction.RESPONSE),
        getClientTransactionResponse: {
          error: false,
          isFetching: false,
          data: finalData,
        },
      }
    }

    case CRMV2Actions.getBuyerRequirementsAction.REQUEST:
    case CRMV2Actions.getBuyerRequirementsAction.FAILURE:
    case CRMV2Actions.getBuyerRequirementsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, CRMV2Actions.getBuyerRequirementsAction, CRMV2Actions.getBuyerRequirementsAction.RESPONSE),
      }
    }

    case CRMV2Actions.getPropertyDetailsAction.REQUEST:
    case CRMV2Actions.getPropertyDetailsAction.FAILURE:
    case CRMV2Actions.getPropertyDetailsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, CRMV2Actions.getPropertyDetailsAction, CRMV2Actions.getPropertyDetailsAction.RESPONSE),
      }
    }

    case CRMV2Actions.uploadImageAction.REQUEST:
    case CRMV2Actions.uploadImageAction.FAILURE:
    case CRMV2Actions.uploadImageAction.CLEAR:
    case CRMV2Actions.uploadImageAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          CRMV2Actions.uploadImageAction,
          CRMV2Actions.uploadImageAction.RESPONSE),
      }
    }

    case CRMV2Actions.getClientsFilters.REQUEST:
    case CRMV2Actions.getClientsFilters.FAILURE:
    case CRMV2Actions.getClientsFilters.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          CRMV2Actions.getClientsFilters,
          CRMV2Actions.getClientsFilters.RESPONSE),
      }
    }

    case CRMV2Actions.updateClientTransactionAction.REQUEST:
    case CRMV2Actions.updateClientTransactionAction.FAILURE:
    case CRMV2Actions.updateClientTransactionAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          CRMV2Actions.updateClientTransactionAction,
          CRMV2Actions.updateClientTransactionAction.RESPONSE),
      }
    }

    case CRMV2Actions.updateBuyerRequirementsAction.REQUEST:
    case CRMV2Actions.updateBuyerRequirementsAction.FAILURE:
    case CRMV2Actions.updateBuyerRequirementsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          CRMV2Actions.updateBuyerRequirementsAction,
          CRMV2Actions.updateBuyerRequirementsAction.RESPONSE),
      }
    }

    case CRMV2Actions.changeCardOrderAction.REQUEST:
    case CRMV2Actions.changeCardOrderAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          CRMV2Actions.changeCardOrderAction,
          CRMV2Actions.changeCardOrderAction.RESPONSE),
      }
    }
    case CRMV2Actions.changeCardStageAction.REQUEST:
    case CRMV2Actions.changeCardStageAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          CRMV2Actions.changeCardStageAction,
          CRMV2Actions.changeCardStageAction.RESPONSE),
      }
    }
    case CRMV2Actions.changeCardStageAction.SUCCESS:
    case CRMV2Actions.changeCardOrderAction.SUCCESS: {
      const { dragDropColumns } = action.data
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          CRMV2Actions.changeCardOrderAction,
          CRMV2Actions.changeCardOrderAction.RESPONSE),
        getClientTransactionResponse: {
          ...state.getClientTransactionResponse,
          data: {
            ...state.getClientTransactionResponse.data,
            dragDropColumns,
          },
        },
      }
    }

    case CRMV2Actions.setReminderAction.REQUEST:
    case CRMV2Actions.setReminderAction.SUCCESS:
    case CRMV2Actions.setReminderAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          CRMV2Actions.setReminderAction,
          CRMV2Actions.setReminderAction.RESPONSE,
        ),
      }
    }

    case CRMV2Actions.getClientTransactionByIdAction.REQUEST:
    case CRMV2Actions.getClientTransactionByIdAction.SUCCESS:
    case CRMV2Actions.getClientTransactionByIdAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          CRMV2Actions.getClientTransactionByIdAction,
          CRMV2Actions.getClientTransactionByIdAction.RESPONSE,
        ),
      }
    }

    case CRMV2Actions.getAgentDetailsAction.REQUEST:
    case CRMV2Actions.getAgentDetailsAction.SUCCESS:
    case CRMV2Actions.getAgentDetailsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          CRMV2Actions.getAgentDetailsAction,
          CRMV2Actions.getAgentDetailsAction.RESPONSE,
        ),
      }
    }

    case CRMV2Actions.currentTopFilterTypeAction.type: {
      const {
        type,
      } = action.data
      return {
        ...state,
        currentTopFilter: type,
      }
    }

    case CRMV2Actions.setViewDeleteNoteModal.type: {
      return {
        ...state,
        isShowDeleteNoteModal: action.data,
      }
    }

    case CRMV2Actions.setViewDeleteReminderModal.type: {
      return {
        ...state,
        isShowDeleteReminderModal: action.data,
      }
    }

    case CRMV2Actions.updateFilterQueriesTypeAction.type: {
      const {
        filters,
      } = action.data

      return {
        ...state,
        currentFilters: filters,
      }
    }

    case CRMV2Actions.setCurrentClientTransactionIDAction.type: {
      const {
        id,
        deal_id,
      } = action.data

      return {
        ...state,
        currentClientTransactionID: id,
        currentCRMDealId: deal_id,
      }
    }

    case CRMV2Actions.toggleReminderModalAction.type: {
      const {
        data,
        bool,
      } = action.data

      return {
        ...state,
        reminderEditableData: data,
        isReminderModalOpen: bool,
      }
    }

    case CRMV2Actions.addNotesAction.REQUEST:
    case CRMV2Actions.addNotesAction.FAILURE:
    case CRMV2Actions.addNotesAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          CRMV2Actions.addNotesAction,
          CRMV2Actions.addNotesAction.RESPONSE),
      }
    }

    case CRMV2Actions.updateNotesAction.REQUEST:
    case CRMV2Actions.updateNotesAction.FAILURE:
    case CRMV2Actions.updateNotesAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          CRMV2Actions.updateNotesAction,
          CRMV2Actions.updateNotesAction.RESPONSE),
      }
    }

    case CRMV2Actions.getNotesAction.REQUEST:
    case CRMV2Actions.getNotesAction.FAILURE:
    case CRMV2Actions.getNotesAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          CRMV2Actions.getNotesAction,
          CRMV2Actions.getNotesAction.RESPONSE),
      }
    }

    case CRMV2Actions.deleteNotesAction.REQUEST:
    case CRMV2Actions.deleteNotesAction.FAILURE:
    case CRMV2Actions.deleteNotesAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          CRMV2Actions.deleteNotesAction,
          CRMV2Actions.deleteNotesAction.RESPONSE),
      }
    }

    case CRMV2Actions.getTimelineAction.REQUEST:
    case CRMV2Actions.getTimelineAction.FAILURE:
    case CRMV2Actions.getTimelineAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          CRMV2Actions.getTimelineAction,
          CRMV2Actions.getTimelineAction.RESPONSE),
      }
    }

    case CRMV2Actions.getFinancingForTransactionAction.REQUEST:
    case CRMV2Actions.getFinancingForTransactionAction.FAILURE:
    case CRMV2Actions.getFinancingForTransactionAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          CRMV2Actions.getFinancingForTransactionAction,
          CRMV2Actions.getFinancingForTransactionAction.RESPONSE),
      }
    }

    case CRMV2Actions.createFinancingForTransactionAction.REQUEST:
    case CRMV2Actions.createFinancingForTransactionAction.FAILURE:
    case CRMV2Actions.createFinancingForTransactionAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          CRMV2Actions.createFinancingForTransactionAction,
          CRMV2Actions.createFinancingForTransactionAction.RESPONSE),
      }
    }

    case CRMV2Actions.updateClientAction.REQUEST:
    case CRMV2Actions.updateClientAction.FAILURE:
    case CRMV2Actions.updateClientAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          CRMV2Actions.updateClientAction,
          CRMV2Actions.updateClientAction.RESPONSE),
      }
    }

    case CRMV2Actions.fetchClientReminderAction.REQUEST:
    case CRMV2Actions.fetchClientReminderAction.FAILURE:
    case CRMV2Actions.fetchClientReminderAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          CRMV2Actions.fetchClientReminderAction,
          CRMV2Actions.fetchClientReminderAction.RESPONSE),
      }
    }

    case CRMV2Actions.toggleAgentFinancePreferenceAction.REQUEST:
    case CRMV2Actions.toggleAgentFinancePreferenceAction.FAILURE:
    case CRMV2Actions.toggleAgentFinancePreferenceAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          CRMV2Actions.toggleAgentFinancePreferenceAction,
          CRMV2Actions.toggleAgentFinancePreferenceAction.RESPONSE),
      }
    }

    case CRMV2Actions.addCoClientDetailsAction.REQUEST:
    case CRMV2Actions.addCoClientDetailsAction.FAILURE:
    case CRMV2Actions.addCoClientDetailsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          CRMV2Actions.addCoClientDetailsAction,
          CRMV2Actions.addCoClientDetailsAction.RESPONSE),
      }
    }

    case CRMV2Actions.archiveLeadAction.REQUEST:
    case CRMV2Actions.archiveLeadAction.FAILURE:
    case CRMV2Actions.archiveLeadAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          CRMV2Actions.archiveLeadAction,
          CRMV2Actions.archiveLeadAction.RESPONSE),
      }
    }

    case CRMV2Actions.updateCoClientDetailsAction.REQUEST:
    case CRMV2Actions.updateCoClientDetailsAction.FAILURE:
    case CRMV2Actions.updateCoClientDetailsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          CRMV2Actions.updateCoClientDetailsAction,
          CRMV2Actions.updateCoClientDetailsAction.RESPONSE),
      }
    }

    case CRMV2Actions.getArchivedLeadAction.REQUEST:
    case CRMV2Actions.getArchivedLeadAction.FAILURE:
    case CRMV2Actions.getArchivedLeadAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          CRMV2Actions.getArchivedLeadAction,
          CRMV2Actions.getArchivedLeadAction.RESPONSE),
      }
    }

    case CRMV2Actions.deleteCoClientDetailsAction.REQUEST:
    case CRMV2Actions.deleteCoClientDetailsAction.FAILURE:
    case CRMV2Actions.deleteCoClientDetailsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          CRMV2Actions.deleteCoClientDetailsAction,
          CRMV2Actions.deleteCoClientDetailsAction.RESPONSE),
      }
    }

    case CRMV2Actions.unArchiveLeadAction.REQUEST:
    case CRMV2Actions.unArchiveLeadAction.FAILURE:
    case CRMV2Actions.unArchiveLeadAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          CRMV2Actions.unArchiveLeadAction,
          CRMV2Actions.unArchiveLeadAction.RESPONSE),
      }
    }

    case CRMV2Actions.deleteClientTransactionAction.REQUEST:
    case CRMV2Actions.deleteClientTransactionAction.FAILURE:
    case CRMV2Actions.deleteClientTransactionAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          CRMV2Actions.deleteClientTransactionAction,
          CRMV2Actions.deleteClientTransactionAction.RESPONSE),
      }
    }

    case CRMV2Actions.updateFinancingForTransactionAction.REQUEST:
    case CRMV2Actions.updateFinancingForTransactionAction.FAILURE:
    case CRMV2Actions.updateFinancingForTransactionAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          CRMV2Actions.updateFinancingForTransactionAction,
          CRMV2Actions.updateFinancingForTransactionAction.RESPONSE),
      }
    }

    case CRMV2Actions.setIsViewListing.type: {
      return {
        ...state,
        isViewListing: action.data,
      }
    }

    case CRMV2Actions.setIsViewContract.type: {
      return {
        ...state,
        isViewContract: action.data,
      }
    }

    case CRMV2Actions.setIsViewOffer.type: {
      return {
        ...state,
        isViewOffer: action.data,
      }
    }

    case CRMV2Actions.setIsAddListing.type: {
      return {
        ...state,
        isAddListing: action.data,
      }
    }

    case CRMV2Actions.setIsAddOffer.type: {
      return {
        ...state,
        isAddOffer: action.data,
      }
    }

    case CRMV2Actions.setIsEndContract.type: {
      return {
        ...state,
        isEndContract: action.data,
      }
    }

    case CRMV2Actions.setIsViewListingToContract.type: {
      return {
        ...state,
        isViewListingToContract: action.data,
      }
    }

    case CRMV2Actions.setIsViewOfferToContract.type: {
      return {
        ...state,
        isViewOfferToContract: action.data,
      }
    }

    case CRMV2Actions.setCannotConvertContract.type: {
      return {
        ...state,
        cannotConvertContract: action.data,
      }
    }

    case CRMV2Actions.setIsClosed.type: {
      return {
        ...state,
        isClosed: action.data,
      }
    }

    case CRMV2Actions.setIsCreateWarning.type: {
      return {
        ...state,
        isCreateWarning: action.data,
      }
    }

    case CRMV2Actions.setStatusValue.type: {
      return {
        ...state,
        statusValue: action.data,
      }
    }

    case CRMV2Actions.emptyDetailsWarningAction.type: {
      return {
        ...state,
        isShowWarning: action.data,
      }
    }

    case CRMV2Actions.createClientTransactionAction.REQUEST:
    case CRMV2Actions.createClientTransactionAction.FAILURE:
    case CRMV2Actions.createClientTransactionAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          CRMV2Actions.createClientTransactionAction,
          CRMV2Actions.createClientTransactionAction.RESPONSE),
      }
    }

    case CRMV2Actions.updateReminderAction.REQUEST:
    case CRMV2Actions.updateReminderAction.FAILURE:
    case CRMV2Actions.updateReminderAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(state,
          action,
          CRMV2Actions.updateReminderAction,
          CRMV2Actions.updateReminderAction.RESPONSE),
      }
    }

    default:
      return {
        ...state,
      }
  }
}
